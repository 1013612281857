//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class SenderService {
  getSenders(filterObj) {
    return axios.get(
      "/api/getSenders",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addSender(SenderData) {
    return axios({
      method: "POST",
      url: "/api/addSender",
      data: SenderData,
      headers: authHeader(),
    });
  }
  editSender(SenderData) {
    return axios({
      method: "POST",
      data: SenderData,
      url: "/api/editSender",
      headers: authHeader(),
    });
  }
  deleteSender(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteSender",
      headers: authHeader(),
    });
  }

  getAllSenders() {
    return axios.get("/api/getAllSenders");
  }
  getSenderInfo(customerId) {
    return axios.get(
      "/api/getSenderInfo/" + customerId,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterSenders(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterSenders",
      headers: authHeader(),
    });
  }
}

export default new SenderService();
