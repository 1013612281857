import { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import TransactionService from "../../services/transaction.service";
import UserService from "../../services/user.service";
import SenderService from "../../services/sender.service";
import AgentService from "../../services/agent.service";
import ReceiverService from "../../services/receiver.service";
import CurrenyService from "../../services/currency.service";
import CountrydetailService from "../../services/countrydetail.service";
import RateService from "../../services/rate.service";
import InputMask from "react-input-mask";
import _ from "lodash";
import moment from "moment-timezone";
import "@animxyz/core";
import "./transaction.css";
import { XyzTransition } from "@animxyz/react";
moment.tz.setDefault("Africa/Asmara");
const Add = ({ handlePageSwitch, currentUser }) => {
  const [sName, setsName] = useState("");
  const [sCity, setsCity] = useState("");
  const [sAmount, setsAmount] = useState("");
  const [sCountry, setsCountry] = useState("");
  const [sCurrency, setsCurrency] = useState("");
  const [sPhone, setsPhone] = useState("");
  const [sEmail, setsEmail] = useState("");
  const [rName, setrName] = useState("");
  const [rCity, setrCity] = useState("");
  const [rAmount, setrAmount] = useState("");
  const [rCountry, setrCountry] = useState("");
  const [rCurrency, setrCurrency] = useState("");
  const [rPhone, setrPhone] = useState("");
  const [rEmail, setrEmail] = useState("");
  const [agentId, setAgentId] = useState("");
  const [referenceP, setReference] = useState("");
  const [agents, setAgents] = useState([]);
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [showDuplicateModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleCloseAddModal = () => setShowAddModal(false);
  const [cardState, setCardState] = useState(true);
  const [searchPhone, setSearchPhone] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [conversionRate, setConvertionRate] = useState(0);
  const [receiverInfo, setReceiverInfo] = useState([]);
  const [receivers, setReceivers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [senderCountries, setSenderCountries] = useState([]);
  const [receiverCountries, setReceiverCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [rates, setRates] = useState([]);
  const [fromRates, setFromRates] = useState([]);
  const [toRates, setToRates] = useState([]);
  const [InfoDisable, setInfoDisable] = useState(false);
  const [selectedReceipent, setSelectedReceipent] = useState({});
  async function handleAdd() {
    if (cardState) {
      const txObject = {
        sAmount: sAmount,
        sCurrencyId: sCurrency,
        rAmount: rAmount,
        rCurrencyId: rCurrency,
        userId: currentUser.id,
        agentId: agentId,
        // ticketNo: "T" + _.times(9, () => _.random(35).toString(36)).join(""),
      };
      const senderObject = {
        name: sName,
        city: sCity,
        email: sEmail,
        agentId: agentId,
        countryId: sCountry,
        userId: currentUser.id,
        phone: sPhone,
      };
      const receiverObject = {
        name: rName,
        city: rCity,
        countryId: rCountry,
        email: rEmail,
        userId: currentUser.id,
        agentId: agentId,
        phone: rPhone,
        //senderId: senderId,
      };
      //validate data before sending
      if (
        sName == "" ||
        sAmount == "" ||
        sCountry == "" ||
        sCurrency == "" ||
        rName == "" ||
        rPhone == "" ||
        rAmount == "" ||
        rCurrency == "" ||
        rCountry == "" ||
        agentId == ""
      )
        setMessage(
          "One or more information is missing!  Fields with red (*) are required!"
        );
      else {
        //save sender
        let sender = await SenderService.addSender(senderObject);
        if (sender && sender.data) {
          _.assign(receiverObject, { senderId: sender.data.id });
          let receiverExists = receivers.filter(
            (r) => r.phone == receiverObject.phone
          );
          /* if (receiverExists.length > 0) {
            _.assign(txObject, { senderId: sender.data[0].id });
            _.assign(txObject, { receiverId: receiverExists[0].id });
          } else { */
          let receiver = await ReceiverService.addReceiver(receiverObject);
          if (receiver && receiver.data) {
            _.assign(txObject, { senderId: sender.data.id });
            _.assign(txObject, { receiverId: receiver.data.id });
          }
          // }
          if (txObject.senderId && txObject.receiverId) {
            TransactionService.addTransaction(txObject)
              .then(async (tx) => {
                let _recievers = await ReceiverService.getAllReceivers();
                setReceivers(_recievers.data);
                setErrored("");
                setSucceded("A new Transaction is successfully added!");
                setMessage("");
              })
              .catch((err) => {
                setSucceded("");
                setErrored("New Transaction creation failed!");
                setMessage("");
              });
          }
        }
      }
    } else {
      setShowAddModal(false);
      const txObject = {
        sAmount: fromAmount,
        sCurrencyId: fromCurrency,
        rAmount: toAmount,
        rCurrencyId: toCurrency,
        userId: currentUser.id,
        agentId: agentId,
        // ticketNo: "T" + _.times(9, () => _.random(35).toString(36)).join(""),
      };
      _.assign(txObject, { senderId: selectedReceipent.sender.id });
      _.assign(txObject, { receiverId: selectedReceipent.id });
      /* TransactionService.addTransaction(txObject)
        .then(async (tx) => {
          let _recievers = await ReceiverService.getAllReceivers();
          setReceivers(_recievers.data);
          setrAmount("");
          setsAmount("");
          setErrored("");
          setSucceded("A new Transaction is successfully added!");
          setMessage("");
        })
        .catch((err) => {
          setSucceded("");
          setErrored("New Transaction creation failed!");
          setMessage("");
        }); */
    }
  }
  function handleSelect(receipent) {
    setConvertionRate(0);
    setToCurrency("");
    setFromCurrency("");
    setFromAmount("");
    setToAmount("");
    setAgentId("");
    setSucceded("");
    setErrored("");
    setSelectedReceipent(receipent);
    setShowAddModal(true);
  }
  function handleDuplicateModalAdd() {
    setShowModal(false);
    setShowAddModal(false);
    handleAdd();
  }
  async function isduplicate(type) {
    setErrored("");
    setSucceded("");
    setMessage("");
    setShowAddModal(false);
    let isduplicated = [];
    const rightNow = moment().format("YYYY-MM-DD HH:mm:ss");
    let receiverExists;
    if (type == "new")
      receiverExists = await receivers.filter((r) => r.phone == rPhone);
    if (type == "existing")
      receiverExists = receivers.filter(
        (r) => r.phone == selectedReceipent.phone
      );
    if (receiverExists.length > 0) {
      isduplicated.push({ receiverId: receiverExists[0].id });
      isduplicated.push({
        date: {
          startDate: moment(rightNow)
            .subtract(3, "days")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment(rightNow),
        },
      });

      TransactionService.filterTransactions(isduplicated).then((txns) => {
        if (!!txns && txns.data.length != 0) {
          setShowModal(true);
        } else {
          handleAdd();
        }
      });
    } else handleAdd();
  }
  function handleCurrencyChange(tCurr) {
    if (tCurr == "To") {
      setToCurrency("");
      setConvertionRate(0);
    } else {
      let _rate = rates.filter(
        (r) => r.toCurrencyId == tCurr && r.fromCurrencyId == fromCurrency
      );
      if (_rate.length > 0) {
        setToCurrency(tCurr);
        setConvertionRate(_rate[0].rate);
      }
    }
    setToAmount("");
  }
  function handleRateCalculation(amount) {
    let convertedAmount =
      parseFloat(amount) * parseFloat(conversionRate || rates[0].rate);

    setToAmount(convertedAmount);
    setFromAmount(parseFloat(amount));
  }
  async function searchTransaction() {
    if (searchPhone) {
      setErrored("");
      let recipients = await receivers.filter((reciever) => {
        return reciever.sender.phone == searchPhone;
      });

      if (recipients.length > 0) {
        let _fromRates = [];
        let _toRates = [];
        for (let r of rates) {
          _fromRates.push(r.fromcurrency);
          _toRates.push(r.tocurrency);
        }
        setFromRates(
          _fromRates.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          )
        );
        setToRates(
          _toRates.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          )
        );
        setsPhone(recipients[0].sender.phone);
        setsName(recipients[0].sender.name);
        setsCity(recipients[0].sender.city);
        setsEmail(recipients[0].sender.email);
        setsCountry(recipients[0].sender.countrydetail.id);
        setInfoDisable(true);
        setCardState(false);
      } else {
        setsPhone(searchPhone);
        setsName("");
        setsCity("");
        setsEmail("");
        setInfoDisable(false);
        setCardState(true);
      }
      setReceiverInfo(recipients);
    } else {
      setCardState(false);
      setErrored("Invalid or missing Phone number");
    }
  }
  function calculateAmount(Amount) {
    if (sCurrency != "" && rCurrency != "" && Amount != "") {
      //get the
      let _rateExtracted = rates.filter(
        (r) => r.fromCurrencyId == sCurrency && r.toCurrencyId == rCurrency
      );
      if (_rateExtracted.length == 0)
        setErrored(
          "The rate for the selected currencies are not set. Add the rate first and try again."
        );
      else {
        setrAmount(parseFloat(_rateExtracted[0].rate) * parseFloat(Amount));
        setErrored("");
      }
    }
  }
  useEffect(() => {
    AgentService.filterAgents({ userId: currentUser.id }).then(async (res) => {
      let _countries = await CountrydetailService.getAllCountrydetails();
      let _currencies = await CurrenyService.getAllCurrencys();
      let _recievers = await ReceiverService.getAllReceivers();
      let _rates = await RateService.filterRates({ userId: currentUser.id });
      setRates(_rates.data);
      setCountries(_countries.data);
      setSenderCountries(_countries.data.filter((c) => c.type == "sender"));
      setReceiverCountries(_countries.data.filter((c) => c.type == "receiver"));
      setCurrencies(_currencies.data);
      setReceivers(_recievers.data);
      setAgents(res.data);
    });
  }, []);
  return (
    <div className="addTransaction">
      {/* <XyzTransition appear duration="auto">
        <div
          className="search-row add-row"
          xyz="fade small stagger  duration-6"
        >
          <Button
            variant="warning"
            className="add-back-button"
            size="sm"
            onClick={() => handlePageSwitch()}
          >
            Back
          </Button>
          <InputMask
            className="input--style-4 add-search-input"
            onChange={(e) => setSearchPhone(e.target.value)}
            value={searchPhone}
            placeholder="Enter phone to search"
            mask="(999) 999-9999"
            maskChar=" "
          />

          <Button
            size="sm"
            variant="primary"
            className="apply-filter"
            onClick={() => searchTransaction()}
          >
            Search
          </Button>
        </div>
      </XyzTransition> */}
      {cardState && (
        <div className="page-wrapper bg-gra-02 p-t-130 p-b-100 ">
          <div className="wrapper wrapper--w680">
            <div className="card card-4">
              <div className="card-body">
                <form method="POST">
                  <div className="sender-details">
                    <h4 className="sub-title">
                      <u>Sender Details:</u>{" "}
                    </h4>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Name<span className="required"></span>
                          </label>
                          <InputMask
                            className="input--style-4"
                            value={sName}
                            disabled={InfoDisable}
                            onChange={(e) => setsName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">City</label>
                          <input
                            className="input--style-4"
                            value={sCity}
                            type="text"
                            name="first_name"
                            disabled={InfoDisable}
                            onChange={(e) => setsCity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">Phone</label>
                          <InputMask
                            className="input--style-4"
                            value={sPhone}
                            /* disabled */
                            onChange={(e) => setsPhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Country<span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              /* defaultValue={sCountry} */
                              disabled={InfoDisable}
                              onChange={(e) => setsCountry(e.target.value)}
                            >
                              <option disabled="disabled" selected="selected">
                                Choose Country
                              </option>
                              {senderCountries.map((c) => {
                                return (
                                  <option value={c.id}>{c.country}</option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">Email</label>
                          <div className="input-group-icon">
                            <input
                              className="input--style-4 js-datepicker"
                              type="text"
                              value={sEmail}
                              disabled={InfoDisable}
                              onChange={(e) => setsEmail(e.target.value)}
                            />
                            <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="receiver-details">
                    <h4 className="sub-title">
                      <u>Receiver Details:</u>
                    </h4>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Name <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            onChange={(e) => setrName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">City</label>
                          <input
                            className="input--style-4"
                            type="text"
                            onChange={(e) => setrCity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Phone/ACCT <span className="required"></span>
                          </label>
                          <InputMask
                            className="input--style-4"
                            value={rPhone}
                            onChange={(e) => setrPhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Country<span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => setrCountry(e.target.value)}
                            >
                              <option disabled="disabled" selected="selected">
                                Choose Country
                              </option>
                              {receiverCountries.map((c) => {
                                return (
                                  <option value={c.id}>{c.country}</option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-2">
                        <div className="input-group">
                          <label className="label">Email</label>
                          <input
                            className="input--style-4"
                            type="text"
                            onChange={(e) => setrEmail(e.target.value)}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="receiver-details">
                    <h4 className="sub-title">
                      <u>Amount details:</u>
                    </h4>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Currency <span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => {
                                //setsAmount("");
                                //setrAmount("");
                                setsCurrency(e.target.value);
                              }}
                            >
                              <option disabled="disabled" selected="selected">
                                Sender Currency
                              </option>
                              {senderCountries.map((c) => {
                                return (
                                  <option value={c.currency.id}>
                                    {c.currency.type}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            S Amount <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            type="number"
                            value={sAmount}
                            /* disabled={!sCurrency || !rCurrency} */
                            onChange={(e) => {
                              /* calculateAmount(e.target.value); */
                              setsAmount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Currency <span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => {
                                //setsAmount("");
                                //setrAmount("");
                                setrCurrency(e.target.value);
                              }}
                            >
                              <option disabled="disabled" selected="selected">
                                Receiver Currency
                              </option>
                              {receiverCountries.map((c) => {
                                return (
                                  <option value={c.currency.id}>
                                    {c.currency.type}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            R Amount <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            type="number"
                            value={rAmount}
                            /* disabled */
                            onChange={(e) => setrAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">Text</label>
                          <input
                            className="input--style-4 w-400"
                            type="text"
                            onChange={(e) => setrEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-space">
                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">
                          Agent <span className="required"></span>
                        </label>
                        <div className="rs-select2 js-select-simple select--no-search">
                          <select
                            className="form-control selectpicker"
                            onChange={(e) => setAgentId(e.target.value)}
                          >
                            <option disabled="disabled" selected="selected">
                              Choose Agent
                            </option>
                            {agents.map((agent, index) => {
                              return (
                                <option value={agent.id}>{agent.name}</option>
                              );
                            })}
                          </select>
                          <div className="select-dropdown"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">Reference</label>
                        <input
                          className="input--style-4"
                          type="text"
                          onChange={(e) => setReference(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {!!message && <Alert variant="danger">{message}</Alert>}
                  {!!succeded && <Alert variant="success">{succeded}</Alert>}
                  {!!errored && <Alert variant="danger">{errored}</Alert>}
                  <div className="row row-space">
                    <div className="col-12">
                      <div className="input-group">
                        <div className="flexButtons add-flex-buttons">
                          <Button
                            variant="success"
                            className="add-add-button bg-[#28a745]"
                            onClick={() => isduplicate("new")}
                          >
                            Add
                          </Button>
                          <Button
                            variant="warning"
                            className="add-back-button bg-[#ffc107]"
                            size="sm"
                            onClick={() => handlePageSwitch()}
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {!cardState && (
        <div className="add-search-container">
          {receiverInfo.length > 0 && (
            <div className="search-sender-details">
              <b className="m-r-100">Sender Information</b>
              <div className="name">
                <div>Name:</div>
                <div>{receiverInfo[0].sender.name}</div>
              </div>
              <div className="country">
                <div>Country:</div>
                <div>{receiverInfo[0].sender.countrydetail.country}</div>
              </div>
            </div>
          )}
          {!!succeded && (
            <Alert variant="success">Transaction created successfully</Alert>
          )}
          {!!errored && (
            <Alert variant="danger">Transaction creation failed!</Alert>
          )}
          {receiverInfo.length == 0 && (
            <>
              <div className="noTxText-10">
                No transactions to show... Try searching with phone number or
                add new record
              </div>
            </>
          )}

          <div className="reciever-details">
            {receiverInfo.length > 0 &&
              receiverInfo.map((receipent) => {
                return (
                  <>
                    <Card>
                      <div className="card-body-details"></div>
                      <Card.Header>{receipent.name}</Card.Header>
                      <Card.Body>
                        <XyzTransition appear duration="auto">
                          <div
                            className="receiverInfo"
                            xyz="fade left stagger  duration-10"
                          >
                            <div className="card-text">
                              <div className="card-reciever-item">
                                <span>Country:</span>
                                <span>{receipent.countrydetail.country}</span>
                              </div>
                              <div className="card-reciever-item">
                                <span>Phone :</span>
                                <span>{receipent.phone}</span>
                              </div>
                              <div className="card-reciever-item">
                                <span>City :</span>
                                <span>{receipent.city}</span>
                              </div>
                              <div>
                                <img
                                  src="/send.svg"
                                  alt="send"
                                  onClick={() => handleSelect(receipent)}
                                  className="send-icon"
                                />
                              </div>
                            </div>
                          </div>
                        </XyzTransition>
                      </Card.Body>
                    </Card>
                  </>
                );
              })}
          </div>
          {receiverInfo.length > 0 && (
            <Button
              className="apply-button bg-[#007bff]"
              size="sm"
              onClick={async () => {
                //setSearchPhone("");
                setsName(sName);
                setsPhone(searchPhone);
                setsCountry(sCountry);
                setSucceded("");
                setErrored("");
                setCardState(true);
              }}
            >
              Add New
            </Button>
          )}
        </div>
      )}
      <Modal
        show={showDuplicateModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate transaction found!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-duplicate-info">Are you sure you want to add?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="bg-blue-500"
            onClick={() => handleDuplicateModalAdd()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAddModal}
        onHide={handleCloseAddModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sending Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="rate-calculator-container">
            <div className="rate-calculator-item">
              <InputMask
                className="input--style-4 rate-calculator-input"
                onChange={(e) => handleRateCalculation(e.target.value)}
                placeholder="Enter amount"
                mask="9999999999"
                maskChar=" "
                value={fromAmount}
                disabled={
                  fromCurrency == "" ||
                  fromCurrency == "From" ||
                  toCurrency == "" ||
                  toCurrency == "To"
                }
              />
              <select
                className="form-control selectpicker"
                onChange={(e) => {
                  setToAmount("");
                  setFromAmount("");
                  setFromCurrency(e.target.value);
                }}
              >
                <option selected value="From">
                  From
                </option>
                {fromRates.length > 0 &&
                  fromRates.map((rate) => {
                    return <option value={rate.id}>{rate.type}</option>;
                  })}
              </select>
            </div>
            <img src="/right-arrow.svg" />
            <div className="rate-calculator-item">
              <InputMask
                className="input--style-4 rate-calculator-input"
                /* onChange={(e) => setSearchPhone(e.target.value)} */
                placeholder=""
                mask="9999999999"
                maskChar=" "
                value={toAmount}
                disabled
              />
              <select
                className="form-control selectpicker"
                onChange={(e) => handleCurrencyChange(e.target.value)}
              >
                <option selected value="To">
                  To
                </option>
                <option
                  value={
                    Object.keys(selectedReceipent).length != 0
                      ? selectedReceipent.countrydetail.currency.id
                      : ""
                  }
                >
                  {Object.keys(selectedReceipent).length != 0
                    ? selectedReceipent.countrydetail.currency.type
                    : ""}
                </option>
                {toRates.length > 0 &&
                  toRates.map((rate) => {
                    return rate.type != "USD" ? (
                      ""
                    ) : (
                      <option value={rate.id}>{rate.type}</option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="input-group send-agent">
            <label className="label">
              Agent <span className="required"></span>
            </label>
            <div className="rs-select2 js-select-simple select--no-search">
              <select
                className="form-control selectpicker"
                onChange={(e) => setAgentId(e.target.value)}
              >
                <option disabled="disabled" selected="selected">
                  Choose Agent
                </option>
                {agents.map((agent, index) => {
                  return <option value={agent.id}>{agent.name}</option>;
                })}
              </select>
              <div className="select-dropdown"></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
          <Button
            variant="success"
            className="bg-[#28a745]"
            disabled={
              !toAmount ||
              !fromAmount ||
              fromCurrency == "From" ||
              toCurrency == "To" ||
              agentId == ""
            }
            onClick={() => isduplicate("existing")}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Add;
