import React, { useEffect, useState, useRef } from "react";
import { columns as icolumns, transactions } from "../../configs/local";
import Add from "./add";
import Edit from "./edit";
import { Button, Alert, Glyphicon } from "react-bootstrap";
import TransactionService from "../../services/transaction.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserService from "../../services/user.service";
import configs from "../../configs/local";
import AuthService from "../../services/auth.service";
import AgentService from "../../services/agent.service";
import SenderService from "../../services/sender.service";
import ReceiverService from "../../services/receiver.service";
import BalanceService from "../../services/balance.service";
import InputMask from "react-input-mask";
import CurrenyService from "../../services/currency.service";
import CountrydetailService from "../../services/countrydetail.service";
import LoadingSpinner from "../loadingSpinner";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import "./transaction.css";
import _ from "lodash";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "jspdf-autotable";
const timezone = "Africa/Asmara";
moment.tz.setDefault("Africa/Asmara");

function Pagination({
  data,
  pageLimit,
  dataLimit,
  handleSelection,
  selectedPage,
  setSelectedPage,
  currentUser,
  getCurrency,
  optimize,
}) {
  const [pages] = useState(Math.round(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (selectedPage !== "") setCurrentPage(selectedPage);
  }, [selectedPage]);
  function goToNextPage() {
    setSelectedPage(currentPage + 1);
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setSelectedPage(currentPage - 1);
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setSelectedPage(pageNumber);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div className="trans-table">
      {/* show the posts, 10 posts at a time */}
      <div className="dataContainer sm:m-auto sm:w-[95%]">
        <div className="table-responsive-sm">
          <Table className="table table-hover" xyz-nested>
            <Thead>
              <Tr>
                <Th className="header-color-id">ID</Th>
                {!!currentUser && currentUser.role != "Agent" && (
                  <Th className="header-color-other-info">S Paid</Th>
                )}
                {!!currentUser && currentUser.role != "Agent" && (
                  <Th className="header-color-other-info">Agent</Th>
                )}
                {!optimize && <Th className="header-color-sender">Sender</Th>}
                {!optimize && <Th className="header-color-sender">Amount</Th>}
                {!optimize && <Th className="header-color-sender">Country</Th>}
                <Th className="header-color-receiver">Receiver</Th>
                {!optimize && <Th className="header-color-receiver">City</Th>}
                <Th className="header-color-receiver">Amount</Th>
                <Th className="header-color-receiver">Text</Th>
                {!optimize && (
                  <Th className="header-color-receiver">Country</Th>
                )}
                <Th className="header-color-receiver">Currency</Th>
                <Th className="header-color-receiver">Tele/ACCT</Th>
                <Th className="header-color-other-info">Status</Th>
                <Th className="header-color-other-info">Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getPaginatedData().map((item, index) => {
                return (
                  <Tr>
                    <Td
                      className="nameTag header-color-id"
                      onClick={() => handleSelection(item)}
                    >
                      {item.ticketNo}
                    </Td>
                    {!!currentUser && currentUser.role != "Agent" && (
                      <Td
                        className={
                          item.sPaid == "Paid"
                            ? "statusColor-green-spaid"
                            : "statusColor-green-unpaid"
                        }
                      >
                        {item.sPaid}
                      </Td>
                    )}
                    {!!currentUser && currentUser.role != "Agent" && (
                      <Td>{item.agent.name}</Td>
                    )}

                    {!optimize && <Td>{item.receiver.sender.name}</Td>}
                    {!optimize && (
                      <Td>
                        {parseFloat(item.sAmount).toLocaleString("en-US") ||
                          "N/A"}
                      </Td>
                    )}
                    {!optimize && (
                      <Td>
                        {item.receiver.sender.countrydetail.country || "N/A"}
                      </Td>
                    )}
                    <Td>{item.receiver.name || "N/A"}</Td>
                    {!optimize && <Td>{item.receiver.city || "N/A"}</Td>}
                    <Td>{parseFloat(item.rAmount).toLocaleString("en-US")}</Td>
                    <Td>{item.receiver.email}</Td>
                    {!optimize && (
                      <Td>{item.receiver.countrydetail.country}</Td>
                    )}
                    <Td>{getCurrency(item) || "N/A"}</Td>
                    <Td>{item.receiver.phone}</Td>
                    <Td
                      className={
                        item.status == "Paid"
                          ? "statusColor-green"
                          : item.status == "UnPaid"
                          ? "statusColor-yellow"
                          : item.status == "Cancelled"
                          ? "statusColor-red"
                          : ""
                      }
                    >
                      {/* <button
                        style={{
                          color: "white",
                          fontSize: "10px",
                          width: "20px",
                          height: "20px",
                          backgroundColor:
                            item.status == "Paid"
                              ? "green"
                              : item.status == "UnPaid"
                              ? "red"
                              : "gray",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      >
                        {item.status == "Paid"
                          ? "✔"
                          : item.status == "UnPaid"
                          ? "X"
                          : ""}
                      </button> */}
                      {item.status}
                    </Td>
                    <Td>
                      {moment(item.createdAt).tz(timezone).format("DD-MM-YYYY")}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      </div>
      <div className="paginationAuditContainer">
        <div className="pagination">
          {/* previous button */}
          <button
            onClick={goToPreviousPage}
            className={`prev ${currentPage === 1 ? "disabled" : ""}`}
          >
            Prev
          </button>

          {/* show page numbers */}
          {getPaginationGroup().map((item, index) => (
            <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${
                currentPage === item ? "active" : null
              }`}
            >
              <span>{item}</span>
            </button>
          ))}

          {/* next button */}
          <button
            onClick={goToNextPage}
            className={`next ${currentPage === pages ? "disabled" : ""}`}
          >
            Next
          </button>
        </div>
        <div className="empty"></div>
      </div>
    </div>
  );
}
const Transaction = () => {
  const [optimize, setOptimize] = useState(false);
  const [{ isEdit, isAdd }, setPageState] = useState({ isEdit: 0, isAdd: 0 });
  const [selectedTxn, setSelectedTxn] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().toLocaleString("en", { timeZone: timezone }))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().toLocaleString("en", { timeZone: timezone }))
  );
  const [txData, settxData] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedPage, setSelectedPage] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sAgent, setsAgent] = useState("");
  const [sUser, setsUser] = useState("");
  const [sStatus, setsStatus] = useState("");
  const [sCountry, setsCountry] = useState("");
  const [rCountry, setrCountry] = useState("");
  const [currentUser, setCurrentUser] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agentBalance, setAgentBalance] = useState([]);
  const [senders, setSenders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [senderCountries, setSenderCountries] = useState([]);
  const [receiverCountries, setReceiverCountries] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [notPaidTxn, setNotPaidTxn] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalUnPaid, setTotalUnPaid] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);
  const [nameSearch, setNameSearch] = useState("");
  const [idSearch, setIDSearch] = useState("");
  const [isNotifications, setNotifications] = useState(false);
  const [ignore, setIgnore] = useState(false);
  function handleSelection(item) {
    setSelectedTxn(item);
    setPageState({ isEdit: 1, isAdd: 0 });
  }
  function handleAdd() {
    setPageState({ isEdit: 1, isAdd: 1 });
  }
  function handlePageSwitch(item) {
    setPageState({ isEdit: 0, isAdd: 0 });
    // window.location.reload(false);
  }
  function handlePageSwitchEdit(id, status, comment) {
    const currentTodoIndex = txData.findIndex((todo) => todo.id === id);
    // 2. Mark the todo as complete
    const updatedTodo = {
      ...txData[currentTodoIndex],
      status: status,
      comment: comment,
    };
    // 3. Update the todo list with the updated todo
    const newTodos = [...txData];
    newTodos[currentTodoIndex] = updatedTodo;
    settxData(newTodos);
    setPageState({ isEdit: 0, isAdd: 0 });
    // window.location.reload(false);
  }
  function generatePdf() {
    const doc = new jsPDF();
    const txList = [];
    const tableColumn = [
      "Date",
      "Status",
      "S Name",
      "S country",
      "R name",
      "R Amount",
      "R Phone",
    ];

    txData.forEach((tx) => {
      txList.push([
        moment(tx.createdAt).tz(timezone).format("DD-MM-YYYY"),
        tx.status,
        tx.receiver.sender.name,
        tx.receiver.sender.countrydetail.country,
        tx.receiver.name,
        tx.rAmount,
        tx.receiver.phone,
      ]);
    });
    // tableRows.push(txList);
    doc.autoTable(tableColumn, txList, { startY: 20 });
    doc.text("List of transactions", 14, 15);
    doc.save("Transactions_list.pdf");
  }
  function applyfilter() {
    let filters = [];
    if (nameSearch == "" && idSearch == "") {
      let _agentId;
      if (currentUser.role == "Agent") _agentId = currentUser.id;
      if (currentUser.role == "User") _agentId = sAgent;
      if (_agentId) filters.push({ agentId: _agentId });
      if (sStatus != "") filters.push({ status: sStatus });
      /* if (sCountry != "") filters.push({ receiverId: sCountry }); */
      if (rCountry != "")
        filters.push({
          receiverId: extractId(rCountry),
        });
      filters.push({
        date: {
          startDate: moment(startDate)
            .startOf("day")
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment(endDate)
            .endOf("day")
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss"),
        },
      });
      TransactionService.filterTransactions(filters).then((txns) => {
        setErrored("");
        settxData(txns.data);
        updateTotals(txns.data);
      });
    } else {
      if (nameSearch != "")
        filters.push({
          name: nameSearch,
          agentId: currentUser.role == "Agent" ? currentUser.id : "",
        });
      if (idSearch != "")
        filters.push({
          ticketNo: idSearch,
          agentId: currentUser.role == "Agent" ? currentUser.id : "",
        });
      console.log("FILTERS...", filters);
      TransactionService.getTransactionInfo(filters).then((txns) => {
        setErrored("");
        settxData(txns.data);
        // updateTotals(txns.data);
      });
    }
  }
  function extractId(id) {
    let _receiver = receivers.filter((tx) => tx.countrydetail.id == id);
    return _receiver.length > 0 ? _receiver[0].id : "";
  }
  function getCurrency(item) {
    let curr = currencies.filter((c) => c.id == item.rCurrencyId);
    return curr[0].type;
  }
  function updateTotals(txns) {
    let paid = 0,
      pending = 0,
      unPaid = 0,
      cancelled = 0;
    txns.forEach((t) => {
      if (t.status == "Paid") paid = paid + parseFloat(t.rAmount);
      if (t.status == "Pending") pending = pending + parseFloat(t.rAmount);
      if (t.status == "UnPaid") unPaid = unPaid + parseFloat(t.rAmount);
      if (t.status == "Cancelled") cancelled = unPaid + parseFloat(t.rAmount);
    });
    setTotalPaid(paid);
    setTotalPending(pending);
    setTotalUnPaid(unPaid);
    setTotalCancelled(cancelled);
  }
  function getEnquires() {
    let enquires;
    if (currentUser.role == "User") {
      enquires = txData.filter((tx) => tx.status == "UnPaid");
    } else {
      enquires = txData.filter(
        (tx) => tx.comment != "" && tx.comment != null && tx.status == "Pending"
      );
    }
    if (enquires.length > 0) {
      console.log("enquires..", enquires);
      setNotifications(true);
      settxData(enquires);
    }
  }
  useEffect(() => {
    const today = [
      {
        date: {
          startDate: moment()
            .subtract(30, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        },
      },
    ];
    TransactionService.filterTransactions(today)
      .then(async (res) => {
        const allTxns = res.data;
        var tempTXData = res.data;
        let usersList = await UserService.getUsers();
        let loggedInUser = await AuthService.getCurrentUser();
        let agentsList = await AgentService.getAllAgents();
        setCurrentUser(loggedInUser);
        if (loggedInUser.role == "Admin") tempTXData = allTxns; //settxData(allTxns);
        if (loggedInUser.role == "User") {
          let userTxData = allTxns.filter(
            (txn) => txn.userId == loggedInUser.id
          );
          //settxData(userTxData);
          tempTXData = userTxData;
          updateTotals(userTxData);
          //calculate how many of them has status UnPaid
          let np_status = userTxData.filter((tx) => tx.status == "UnPaid");
          setNotPaidTxn(np_status.length);
        }
        if (loggedInUser.role == "Agent") {
          let agentTxData = allTxns.filter(
            (txn) => txn.agentId == loggedInUser.id
          );
          let agentBalance = await BalanceService.getBalance(loggedInUser.id);
          setAgentBalance(agentBalance.data);
          //settxData(agentTxData);
          tempTXData = agentTxData;
          updateTotals(agentTxData);
        }
        let currencies = await CurrenyService.getAllCurrencys();
        let _countries = await CountrydetailService.getAllCountrydetails();
        let _recievers = await ReceiverService.getAllReceivers();
        setReceivers(_recievers.data);
        setSenderCountries(_countries.data.filter((c) => c.type == "sender"));
        setReceiverCountries(
          _countries.data.filter((c) => c.type == "receiver")
        );
        setUsers(usersList.data);
        setAgents(agentsList.data);
        setCurrencies(currencies.data);
        setIsLoading(false);

        //check for notifications
        let enquires;
        if (loggedInUser.role == "User" && !ignore) {
          enquires = tempTXData.filter((tx) => tx.status == "UnPaid");
        } else {
          enquires = tempTXData.filter(
            (tx) =>
              tx.comment != "" && tx.comment != null && tx.status == "Pending"
          );
        }
        /* assign the list of transactions */
        if (enquires.length > 0 && !ignore) {
          setNotifications(true);
          settxData(enquires);
        } else {
          setNotifications(false);
          settxData(tempTXData);
        }
        setTimeout(() => {
          setShowInfo(true);
        }, 500);
      })
      .catch((err) => {});
  }, [isAdd, ignore]);
  return (
    <div className="transactions">
      {!isEdit ? (
        <>
          <XyzTransition appear duration="auto">
            {(!isNotifications || ignore) && (
              <div className="search-row" xyz="fade small stagger  duration-6">
                <div className="button-container" xyz-nested>
                  <div className="tx-enhancements">
                    <label
                      className="optimize-label"
                      onClick={() => setOptimize(!optimize)}
                    >
                      Optimize
                    </label>
                    {/* <label
                      className="enquiries-label"
                      onClick={() => getEnquires()}
                    >
                      Enquiries
                    </label> */}
                  </div>
                  {!!currentUser && currentUser.role != "Agent" && (
                    <Button
                      className="bg-[#007bff]"
                      size="sm"
                      onClick={() => handleAdd()}
                    >
                      Add New
                    </Button>
                  )}
                  <Button
                    size="sm"
                    className="bg-[#28a745] hover:opacity-60"
                    onClick={() => generatePdf()}
                  >
                    Download
                  </Button>
                </div>
                <div className="search-row-filters">
                  <div className="filter-selects">
                    {!!currentUser && currentUser.role == "Admin" && (
                      <div>
                        <select
                          className="form-control selectpicker"
                          onChange={(e) => setsUser(e.target.value)}
                          xyz-nested
                        >
                          <option disabled="disabled" selected="selected">
                            Select user
                          </option>
                          {users.map((item, index) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                        <select
                          className="form-control selectpicker"
                          onChange={(e) => setsAgent(e.target.value)}
                          xyz-nested
                        >
                          <option disabled="disabled" selected="selected">
                            Select agent
                          </option>
                          {agents.length != 0 &&
                            agents.map((agent, index) => {
                              return (
                                <option value={agent.id}>{agent.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                    {!!currentUser && currentUser.role == "User" && (
                      <div>
                        <select
                          className="form-control selectpicker"
                          onChange={(e) => setsAgent(e.target.value)}
                          xyz-nested
                        >
                          <option disabled="disabled" selected="selected">
                            Select agent
                          </option>
                          {agents.length != 0 &&
                            agents.map((agent, index) => {
                              return (
                                <>
                                  {!!agent &&
                                    agent.userId == currentUser.id && (
                                      <option value={agent.id}>
                                        {agent.name}
                                      </option>
                                    )}
                                </>
                              );
                            })}
                        </select>
                      </div>
                    )}
                    <div xyz-nested>
                      <select
                        className="form-control selectpicker"
                        onChange={(e) => setsStatus(e.target.value)}
                      >
                        <option disabled="disabled" selected="selected">
                          Select status
                        </option>
                        {configs.statuses.map((s, index) => {
                          return <option value={s}>{s}</option>;
                        })}
                      </select>
                    </div>
                    {/* <div xyz-nested>
                  <select
                    className="form-control selectpicker"
                    onChange={(e) => setrCountry(e.target.value)}
                  >
                    <option disabled="disabled" selected="selected">
                      Receiver country
                    </option>
                    {receiverCountries.length != 0 &&
                      receiverCountries.map((country, index) => {
                        return (
                          <option value={country.id}>{country.country}</option>
                        );
                      })}
                  </select>
                </div> */}
                  </div>
                  <div className="filter-dates">
                    <div className="datepickercss" xyz-nested>
                      <div className="date-item">
                        <div className="m-t-5">Start Date</div>
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          onChange={(Date) => setStartDate(Date)}
                          dateFormat="dd/MM/yyyy"
                          placeholder="Start Date"
                        />
                      </div>
                      <div className="date-item" xyz-nested>
                        <div className="m-t-5"> End Date</div>
                        <DatePicker
                          className="form-control"
                          selected={endDate}
                          onChange={(Date) => setEndDate(Date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-inputs">
                    <InputMask
                      className="input--style-4 tx-search-input"
                      placeholder="ID"
                      type="number"
                      onChange={(e) => setIDSearch(e.target.value)}
                    />
                    <InputMask
                      className="input--style-4 tx-search-input"
                      placeholder="Name"
                      onChange={(e) => setNameSearch(e.target.value)}
                    />
                  </div>
                  <div className="filter-buttons">
                    <Button
                      className="apply-button bg-[#007bff] hover:opacity-60"
                      size="sm"
                      onClick={() => applyfilter()}
                    >
                      Search
                    </Button>
                    <Button
                      size="sm"
                      className="apply-button bg-[#28a745] hover:opacity-60"
                      onClick={() => window.location.reload(false)}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {isNotifications && !ignore && (
              <div className="search-row" xyz="fade small stagger  duration-6">
                <div className="button-container" xyz-nested>
                  {!!currentUser && currentUser.role != "Agent" && (
                    <div className="flex justify-center items-center">
                      <img
                        src="/reminder.png"
                        className="w-[50px] h-[50px]"
                        alt="reminder"
                      />
                      <h2>
                        Please resolve the notifications and refresh to proceed!
                      </h2>
                    </div>
                  )}
                  {!!currentUser && currentUser.role == "Agent" && (
                    <>
                      <h2>Resolved notifications reminder!</h2>
                      <Button
                        size="sm"
                        type="submit"
                        variant="warning"
                        className="bg-[#ffc107]"
                        onClick={() => setIgnore(true)}
                      >
                        Ignore
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </XyzTransition>
          <XyzTransition appear duration="auto">
            <div
              className="log-pagination"
              xyz="fade small stagger  duration-1"
            >
              {!!errored && <Alert variant="danger">{errored}</Alert>}

              {!isLoading && txData.length > 0 && (
                <Pagination
                  xyz-nested
                  data={txData}
                  pageLimit={10}
                  dataLimit={10}
                  setPageState={setPageState}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  handleSelection={handleSelection}
                  currentUser={currentUser}
                  getCurrency={getCurrency}
                  optimize={optimize}
                />
              )}
              {!isLoading && txData.length == 0 && (
                <div className="noTxText">No transactions to show...</div>
              )}
              {isLoading && <LoadingSpinner></LoadingSpinner>}
              <hr className="sm:m-auto sm:w-[80%]"></hr>
              <div className="footer-info sm:justify-center items-center sm:mt-4 sm:m-auto sm:w-[80%]">
                {!!currentUser && currentUser.role == "Agent" && (
                  <div className="balanceInformation ">
                    <div className="footer-item">
                      <div className="footer-item-header">$USD</div>
                      <div className="footer-item-value">
                        {" "}
                        {parseFloat(agentBalance.userUsdBalance).toLocaleString(
                          "en-US"
                        )}
                      </div>
                    </div>
                    <div className="footer-item">
                      <div className="footer-item-header">Local</div>
                      <div className="footer-item-value">
                        {" "}
                        {parseFloat(
                          agentBalance.userLocalBalance
                        ).toLocaleString("en-US")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="footer-item">
                  <div className="footer-item-header-pending">
                    <u>Pending</u>
                  </div>
                  <div className="footer-item-value-pending">
                    {totalPending}
                  </div>
                </div>
                <div className="footer-item">
                  <div className="footer-item-header">
                    <u>Paid</u>
                  </div>
                  <div className="footer-item-value-paid">{totalPaid}</div>
                </div>
                <div className="footer-item">
                  <div className="footer-item-header-cancelled">
                    <u>UnPaid</u>
                  </div>
                  <div className="footer-item-value-unpaid">{totalUnPaid}</div>
                </div>
                <div className="footer-item">
                  <div className="footer-item-header-cancelled">
                    <u>Cancelled</u>
                  </div>
                  <div className="footer-item-value-cancelled">
                    {totalCancelled}
                  </div>
                </div>
              </div>
            </div>
          </XyzTransition>
        </>
      ) : !isAdd ? (
        <Edit
          handlePageSwitchEdit={handlePageSwitchEdit}
          selectedTxn={selectedTxn}
          currentUser={currentUser}
        />
      ) : (
        <Add handlePageSwitch={handlePageSwitch} currentUser={currentUser} />
      )}
    </div>
  );
};
export default Transaction;
