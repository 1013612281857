import React, { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import UserTopUpRequestService from "../../services/userTopUpRequest";
import RateService from "../../services/rate.service";
import AuthService from "../../services/auth.service";
import LoadingSpinner from "../loadingSpinner";
import { countries, currencies } from "../../configs/local";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CountrydetailService from "../../services/countrydetail.service";
import _ from "lodash";
import "@animxyz/core";
import "./rate.css";
import { XyzTransition } from "@animxyz/react";

const Rate = () => {
  const [rateData, setRateData] = useState([]);
  const [addRate, setaddRate] = useState(false);
  const [Amt, setAmount] = useState(0);
  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [rate, setRate] = useState("");
  const [editRate, setEditRate] = useState("");
  const [editIndex, setEditIndex] = useState("");
  const [comment, setComment] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [currentUser, setLoggedInUser] = useState({});
  const [senderCountries, setSenderCountries] = useState([]);
  const [receiverCountries, setReceiverCountries] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      let rateList = await RateService.getAllRates();
      let _countries = await CountrydetailService.getAllCountrydetails();
      setSenderCountries(_countries.data.filter((c) => c.type == "sender"));
      setReceiverCountries(_countries.data.filter((c) => c.type == "receiver"));
      let filteredRates = rateList.data.filter(
        (c) => c.userId == loggedInUser.id
      );
      setRateData(filteredRates);
      setLoggedInUser(loggedInUser);
      setIsLoading(false);
    })();
  }, []);
  function handleSubmit() {
    if (
      fromCurrency == "" ||
      toCurrency == "" ||
      rate == "" ||
      parseInt(rate) <= 0
    ) {
      setSucceded("");
      setErrored("One or more information is missing or invalid ");
    } else {
      let rateObj = {
        userId: currentUser.id,
        fromCurrencyId: fromCurrency,
        toCurrencyId: toCurrency,
        rate: rate,
      };
      //check if it is a duplicate
      let isduplicate = rateData.filter(
        (r) => r.fromCurrencyId == fromCurrency && r.toCurrencyId == toCurrency
      );
      if (isduplicate.length == 0) {
        RateService.addRate(rateObj).then((res) => {
          setErrored("");
          setSucceded("Adding rate was successful");
          window.location.reload(false);
        });
      } else setErrored("The rate is already added. Edit the rate instead.");
    }
  }
  function handleSave(item) {
    if (editRate) {
      RateService.editRate({
        fromCurrencyId: item.fromCurrencyId,
        toCurrencyId: item.toCurrencyId,
        rate: editRate,
      })
        .then((res) => {
          setEditMode(false);
          setSucceded("Rate successfully updated");
          window.location.reload(false);
        })
        .catch((e) => {
          setErrored("Updating rate failed");
        });
    } else setErrored("Rate is invalid or missing");
  }

  return (
    <div>
      <div className="rateBody">
        <XyzTransition appear duration="auto">
          <div className="search-row " xyz="fade small stagger  duration-6">
            {addRate && (
              <div className="rate-form">
                <div className="rate-item">
                  <select
                    className="form-control"
                    style={{ minHeight: "40px" }}
                    onChange={(e) => setFromCurrency(e.target.value)}
                  >
                    <option disabled="disabled" selected="selected">
                      From
                    </option>
                    {senderCountries.map((c) => {
                      return (
                        <option value={c.currency.id}>{c.currency.type}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="rate-item">
                  <select
                    className="form-control"
                    style={{ minHeight: "40px" }}
                    onChange={(e) => setToCurrency(e.target.value)}
                  >
                    <option disabled="disabled" selected="selected">
                      To
                    </option>
                    {receiverCountries.map((c) => {
                      return (
                        <option value={c.currency.id}>{c.currency.type}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="rate-item">
                  <input
                    className="input--style-4"
                    type="number"
                    placeholder="Rate"
                    onChange={(e) => setRate(e.target.value)}
                  />
                </div>
                <div className="rate-item">
                  <Button
                    variant="primary"
                    className="bg-blue-500"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            {!addRate && (
              <Button
                variant="primary"
                className="bg-blue-500"
                onClick={() => setaddRate(true)}
              >
                Add Rate
              </Button>
            )}
          </div>
        </XyzTransition>
        {isLoading && (
          <div className="rate-content">
            <LoadingSpinner></LoadingSpinner>
          </div>
        )}
        {!isLoading && (
          <div className="rate-content">
            {!!succeded && <Alert variant="success">{succeded}</Alert>}
            {!!errored && <Alert variant="danger">{errored}</Alert>}
            <Table className="usersTable">
              <Thead>
                <Tr>
                  <Th>From</Th>
                  <Th>To</Th>
                  <Th>Rate</Th>
                  {editMode && <Th></Th>}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {rateData.map((item, index) => {
                  return (
                    <Tr>
                      <Td>{item.fromcurrency.type}</Td>
                      <Td>{item.tocurrency.type}</Td>
                      <Td>{item.rate}</Td>
                      {!editMode && (
                        <Td>
                          <Button
                            variant="primary"
                            className="bg-blue-500"
                            onClick={() => {
                              setEditIndex(index);
                              setEditMode(true);
                            }}
                          >
                            Edit
                          </Button>{" "}
                        </Td>
                      )}
                      {editMode && index == editIndex && (
                        <Td>
                          <input
                            className="input--style-4"
                            type="number"
                            onChange={(e) => setEditRate(e.target.value)}
                          />
                        </Td>
                      )}
                      {editMode && index == editIndex && (
                        <Td>
                          <Button
                            variant="primary"
                            className="bg-blue-500"
                            onClick={() => handleSave(item)}
                          >
                            Save
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Rate;
