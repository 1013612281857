import React, { Component } from "react";
import "./App.css";
import "./index.css";
import styles from "./index.css";
import "./assets/loadingSpinner.css";
import "./assets/media600.css";
import "./assets/menu.css";
import "./assets/reactToggle.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
//import Home from './components/Home';
import Transaction from "./components/transactions/transaction";
import Login from "./components/login";
import PrivateRoute from "./components/privateRoute";
import PublicRoute from "./components/publicRoute";
import Users from "./components/users/users";
import UserBalance from "./components/Balance/userBalance";
import approveOrRejectRequests from "./components/Requests/approveOrRejectRequests";
import createRequest from "./components/Requests/createRequest";
import createCommission from "./components/commision/createCommision";
import approveOrRejectCommissions from "./components/commision/approveOrRejectCommision";
import CreateNote from "./components/note/createNote";
import Rate from "./components/rate/rates";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <PrivateRoute component={Transaction} path="/Transactions" exact />
          <PrivateRoute component={Users} isActive={true} path="/Users" exact />
          <PrivateRoute component={UserBalance} path="/UserBalance" exact />
          <PrivateRoute
            component={approveOrRejectRequests}
            path="/approveOrRejectRequests"
            exact
          />
          <PrivateRoute component={createRequest} path="/createRequest" exact />
          <PrivateRoute
            component={createCommission}
            path="/createCommission"
            exact
          />
          <PrivateRoute
            component={approveOrRejectCommissions}
            path="/approveOrRejectCommissions"
            exact
          />
          <PrivateRoute component={CreateNote} path="/createNote" exact />
          <PrivateRoute component={Rate} path="/rate" exact />
          <PublicRoute component={Login} path="/login" exact />
          <PublicRoute component={Login} path="/" exact />
          <PublicRoute component={Login} path="/Logout" exact />
        </Switch>
      </Router>
    );
  }
}

export default App;
