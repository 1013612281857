import React, { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import CommissionService from "../../services/commision.service";
import AuthService from "../../services/auth.service";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { countries, currencies } from "../../configs/local";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import _ from "lodash";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import "./commision.css";
const timezone = "America/New_York";

const CreateCommission = () => {
  const [CommissionData, setCommisionData] = useState([]);
  const [addCommission, setAddCommission] = useState(false);
  const [Amt, setAmount] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [currency, setCurrency] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [currentUser, setLoggedInUser] = useState({});
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      let commissionList = await CommissionService.getCommissions();
      let filteredCommission = commissionList.data.filter(
        (c) => c.agentId == loggedInUser.id
      );
      setCommisionData(filteredCommission);
      setLoggedInUser(loggedInUser);
    })();
  }, []);
  function handleSubmit() {
    let commissionData = {
      userId: currentUser.userId,
      agentId: currentUser.id,
      Amt,
      comment,
      status: "Pending",
      currency,
      fromDate,
      toDate,
    };

    if (
      commissionData.userId == "" ||
      commissionData.Amt == "" ||
      commissionData.Amt == "0" ||
      commissionData.currency == ""
    ) {
      setMessage("One or more information is missing or invalid");
      setSucceded("");
      setErrored("");
    } else {
      CommissionService.addCommission(commissionData)
        .then((res) => {
          window.location.reload(false);
        })
        .catch((err) => {});
    }
  }

  return (
    <div>
      <div className="balanceRequestBody">
        <XyzTransition appear duration="auto">
          <div className="search-row" xyz="fade small stagger  duration-6">
            {addCommission && (
              <div className="commision-form">
                <div className="commission-item">
                  From Date
                  <DatePicker
                    className="form-control"
                    selected={fromDate}
                    style={{ marginLeft: "0" }}
                    onChange={(Date) => setFromDate(Date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="commission-item">
                  To Date
                  <DatePicker
                    className="form-control"
                    selected={toDate}
                    style={{ marginLeft: "0" }}
                    onChange={(Date) => setToDate(Date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="commission-item">
                  <input
                    className="input--style-4"
                    type="text"
                    placeholder="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                  ></input>
                </div>
                <div className="commission-item">
                  <select
                    className="form-control"
                    style={{ minHeight: "40px", marginTop: "0px" }}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option disabled="disabled" selected="selected">
                      Currency
                    </option>
                    {currencies.map((c) => {
                      return <option value={c}>{c}</option>;
                    })}
                  </select>
                </div>
                <div className="commission-item">
                  <input
                    className="input--style-4"
                    placeholder="Comment"
                    type="text"
                    onChange={(e) => setComment(e.target.value)}
                  ></input>
                </div>
                <div className="commission-item">
                  <Button
                    variant="primary"
                    className="bg-blue-500"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            {!addCommission && (
              <button
                variant="primary"
                className="commission-add-button bg-blue-500 hover:bg-blue-700 text-white  py-2 px-2 rounded"
                onClick={() => setAddCommission(true)}
              >
                Add Commission
              </button>
            )}
          </div>
        </XyzTransition>
        <div className="commission-content sm:m-auto sm:w-[70%]">
          {!!message && <Alert variant="danger">{message}</Alert>}
          {!!succeded && <Alert variant="success">{succeded}</Alert>}
          {!!errored && <Alert variant="danger">{errored}</Alert>}
          {CommissionData.length != 0 && (
            <Table className="usersTable">
              <Thead>
                <Tr>
                  <Th>From Date</Th>
                  <Th>To Date</Th>
                  <Th>Amount</Th>
                  <Th>Currency</Th>
                  <Th>comment</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {CommissionData.map((item, index) => {
                  return (
                    <Tr>
                      <Td>
                        {moment(item.fromDate)
                          .tz(timezone)
                          .format("DD-MM-YYYY")}
                      </Td>
                      <Td>
                        {moment(item.toDate).tz(timezone).format("DD-MM-YYYY")}
                      </Td>
                      <Td>{parseFloat(item.Amt).toLocaleString("en-US")}</Td>
                      <Td>{item.currency}</Td>
                      <Td>{item.comment || "N/A"}</Td>
                      <Td>
                        <Button
                          variant={
                            item.status == "Accepted"
                              ? "primary"
                              : item.status == "Rejected"
                              ? "danger"
                              : "secondary"
                          }
                          disabled
                        >
                          {item.status}
                        </Button>{" "}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {CommissionData.length == 0 && (
            <>
              <div className="noTxText">No commission to show...</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCommission;
