//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class ReceiverService {
  getReceivers(filterObj) {
    return axios.get(
      "/api/getReceivers",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addReceiver(ReceiverData) {
    return axios({
      method: "POST",
      url: "/api/addReceiver",
      data: ReceiverData,
      headers: authHeader(),
    });
  }
  editReceiver(ReceiverData) {
    return axios({
      method: "POST",
      data: ReceiverData,
      url: "/api/editReceiver",
      headers: authHeader(),
    });
  }
  deleteReceiver(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteReceiver",
      headers: authHeader(),
    });
  }

  getAllReceivers() {
    return axios.get("/api/getAllReceivers");
  }
  getReceiverInfo(customerId) {
    return axios.get(
      "/api/getReceiverInfo/" + customerId,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterReceivers(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterReceivers",
      headers: authHeader(),
    });
  }
}

export default new ReceiverService();
