//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class CountrydetailService {
  getCountrydetails(filterObj) {
    return axios.get(
      "/api/getCountrydetails",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addCountrydetail(CountrydetailData) {
    return axios({
      method: "POST",
      url: "/api/addCountrydetail",
      data: CountrydetailData,
      headers: authHeader(),
    });
  }
  editCountrydetail(CountrydetailData) {
    return axios({
      method: "POST",
      data: CountrydetailData,
      url: "/api/editCountrydetail",
      headers: authHeader(),
    });
  }
  deleteCountrydetail(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteCountrydetail",
      headers: authHeader(),
    });
  }

  getAllCountrydetails() {
    return axios.get("/api/getAllCountrydetails");
  }
  getCountrydetailInfo(customerId) {
    return axios.get(
      "/api/getCountrydetailInfo/" + customerId,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterCountrydetails(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterCountrydetails",
      headers: authHeader(),
    });
  }
}

export default new CountrydetailService();
