import React, { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import BalanceRequestService from "../../services/balanceRequest.service";
import BalanceService from "../../services/balance.service";
import UserService from "../../services/user.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import AuthService from "../../services/auth.service";
import AgentService from "../../services/agent.service";
import LoadingSpinner from "../loadingSpinner";
import "@animxyz/core";
import "./requests.css";
import { XyzTransition } from "@animxyz/react";

const ApproveOrRejectRequests = () => {
  const [requestData, setRequestData] = useState([]);
  const [{ isEdit, isAdd }, setPageState] = useState({ isEdit: 0, isAdd: 0 });
  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);
  const [currentUser, setLoggedInUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      let requestList = await BalanceRequestService.getRequests(
        loggedInUser.id
      );
      let agents = await AgentService.getAllAgents();
      agents = await agents.data.filter((a) => a.userId == loggedInUser.id);
      setAgents(agents);
      setRequestData(requestList.data);
      setLoggedInUser(loggedInUser);
      setIsLoading(false);
    })();
  }, []);
  async function handleAccept(item, status) {
    BalanceRequestService.updateRequest({ id: item.id, status: status })
      .then(async (res) => {
        //update the user balance accordingly
        let agentBalance = await BalanceService.getBalance(item.agent.id);
        let userUsdBalance = agentBalance.data.userUsdBalance;
        let userLocalBalance = agentBalance.data.userLocalBalance;

        if (status == "Accepted") {
          if (item.currency == "USD") {
            userUsdBalance = parseInt(userUsdBalance) + parseInt(item.Amt);
          } else {
            userLocalBalance = parseInt(userLocalBalance) + parseInt(item.Amt);
          }
        }
        BalanceService.updateBalance({
          userLocalBalance: userLocalBalance,
          userUsdBalance: userUsdBalance,
          id: agentBalance.data.id,
        })
          .then((res) => {
            window.location.reload(false);
          })
          .catch((err) => {});
        //window.location.reload(false);
      })
      .catch((err) => {});
  }
  async function handleSearch(agentId) {
    let f_requests = await BalanceRequestService.filterRequests(agentId);
    setRequestData(f_requests.data);
  }

  return (
    <div>
      <div className="balanceRequestBody">
        <XyzTransition appear duration="auto">
          <div className="search-row" xyz="fade small stagger  duration-6">
            <select
              className="form-control selectpicker"
              onChange={(e) => handleSearch(e.target.value)}
              xyz-nested
            >
              <option disabled="disabled" selected="selected">
                Select agent
              </option>
              {agents.map((item, index) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
          </div>
        </XyzTransition>
        {isLoading && (
          <div className="request-content">
            <LoadingSpinner></LoadingSpinner>
          </div>
        )}
        {!isLoading && requestData.length != 0 && (
          <div className="request-content sm:m-auto sm:w-[70%]">
            <Table className="usersTable">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>Currency</Th>
                  <Th>comment</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {requestData.map((item, index) => {
                  return (
                    <Tr>
                      <Td>{item.agent.name}</Td>
                      <Td>{parseFloat(item.Amt).toLocaleString("en-US")}</Td>
                      <Td>{item.currency}</Td>
                      <Td>{item.comment}</Td>
                      {item.status == "Pending" ? (
                        <Td>
                          <Button
                            variant="primary"
                            className="bg-blue-500"
                            onClick={() => handleAccept(item, "Accepted")}
                          >
                            Accept
                          </Button>{" "}
                          <Button
                            variant="danger"
                            className="bg-[#dc3545]"
                            onClick={() => handleAccept(item, "Rejected")}
                          >
                            Reject
                          </Button>
                        </Td>
                      ) : (
                        <Td>
                          <Button
                            variant={
                              item.status == "Accepted" ? "primary" : "danger"
                            }
                            disabled
                          >
                            {item.status}
                          </Button>{" "}
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        )}
        {!isLoading && requestData.length == 0 && (
          <>
            <div className="noTxText">No requests to show...</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApproveOrRejectRequests;
