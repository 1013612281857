import React, { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import UserService from "../../services/user.service";
import BalanceService from "../../services/balance.service";
import AuthService from "../../services/auth.service";
import AgentService from "../../services/agent.service";
import LoadingSpinner from "../loadingSpinner";
import _ from "lodash";
import "./balance.css";
const RightPanel = ({ item }) => {
  return (
    <>
      {item.role == "User" && (
        <div className="balanceInfo">
          <div className="Administrator">
            <div className="userLabel">Administrator</div>
            <div className="balance-details">
              <div className="usdCurrency">
                {parseFloat(item.adminUsdBalance).toLocaleString("en-US")}
                USD
              </div>
              <div className="localCurrency">
                {parseFloat(item.adminLocalBalance).toLocaleString("en-US")}
                {item.localCurrency}
              </div>
            </div>
            <div className="action-buttons">
              <Button className="bg-[#007bff]">Add</Button>
              <Button variant="danger" className="bg-[#dc3545]">
                Deduct
              </Button>
            </div>
          </div>
          <div className="User">
            <div className="userLabel">User</div>
            <div className="balance-details">
              <div className="usdCurrency">
                {" "}
                {parseFloat(item.userUsdBalance).toLocaleString("en-US")}
                USD
              </div>
              <div className="localCurrency">
                {parseFloat(item.userLocalBalance).toLocaleString("en-US")}
                {item.localCurrency}
              </div>
            </div>
            <div className="action-buttons">
              <Button className="bg-[#007bff]">Add</Button>
              <Button variant="danger" className="bg-[#dc3545]">
                Deduct
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const UserBalance = () => {
  const [userData, setUserData] = useState([]);
  const [{ isEdit, isAdd }, setPageState] = useState({ isEdit: 0, isAdd: 0 });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [modalAmt, setModalAmt] = useState(0);
  const [addOrSubtruct, setAddOrSubtruct] = useState("add");
  const [userAmt, setUserAmt] = useState([]);
  const [user, setUser] = useState("");
  const [item, setItem] = useState({});
  const [localCurrency, setLocalCurrency] = useState("");
  const [modalCurrency, setModalCurrency] = useState("");
  const [adminOrUser, setAdminOrUser] = useState("");
  const [rightPanelData, setRightPanelData] = useState("");
  const [active, setActive] = useState("");
  const [balanceData, setBalanceData] = useState([]);
  const [agents, setAgents] = useState([]);
  const [loggedInUserId, setLoggedInUserId] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  function handleUpdate() {
    setShow(false);

    let updatedBalance = 0;

    //check if it is add/subtruct

    if (addOrSubtruct == "add") {
      //check if it USD or local
      if (modalCurrency == "USD") {
        updatedBalance = !!userAmt.USD
          ? parseInt(userAmt.USD) + parseInt(modalAmt)
          : parseInt(modalAmt);
        adminOrUser == "Admin"
          ? (item.adminUsdBalance = updatedBalance)
          : (item.userUsdBalance = updatedBalance);
      } else {
        updatedBalance = !!userAmt.local
          ? parseInt(userAmt.local) + parseInt(modalAmt)
          : parseInt(modalAmt);
        adminOrUser == "Admin"
          ? (item.adminLocalBalance = updatedBalance)
          : (item.userLocalBalance = updatedBalance);
      }
    } else {
      if (modalCurrency == "USD") {
        updatedBalance = !!userAmt.USD
          ? parseInt(userAmt.USD) - parseInt(modalAmt)
          : parseInt(modalAmt);
        adminOrUser == "Admin"
          ? (item.adminUsdBalance = updatedBalance)
          : (item.userUsdBalance = updatedBalance);
      } else {
        updatedBalance = !!userAmt.local
          ? parseInt(userAmt.local) - parseInt(modalAmt)
          : parseInt(modalAmt);
        adminOrUser == "Admin"
          ? (item.adminLocalBalance = updatedBalance)
          : (item.userLocalBalance = updatedBalance);
      }
    }
    BalanceService.updateBalance({
      adminLocalBalance: item.adminLocalBalance,
      adminUsdBalance: item.adminUsdBalance,
      userLocalBalance: item.userLocalBalance,
      userUsdBalance: item.userUsdBalance,
      id: item.id,
    })
      .then((res) => {
        //window.location.reload(false);
      })
      .catch((err) => {});
  }
  function handleModalOpen(
    userId,
    uBalance,
    action,
    currency,
    adminOrUser,
    item
  ) {
    setUserAmt(uBalance);
    setUser(userId);
    setLocalCurrency(currency);
    setModalCurrency(currency);
    setAddOrSubtruct(action);
    setAdminOrUser(adminOrUser);
    setItem(item);
    setShow(true);
  }
  useEffect(() => {
    UserService.getUsers()
      .then(async (res) => {
        const response = res.data;
        let loggedInUser = await AuthService.getCurrentUser();
        let _balances = await BalanceService.getBalances();
        let agentsList = await AgentService.getAllAgents();
        if (loggedInUser.role == "Admin") setBalanceData(_balances.data);
        if (loggedInUser.role == "User") {
          let userBlData = _balances.data.filter(
            (bl) => bl.userId == loggedInUser.id
          );
          if (userBlData.length > 0) {
            setActive(userBlData[0].agent.name);
            setRightPanelData(userBlData[0]);
            setBalanceData(userBlData);
          }
        }

        setAgents(agentsList.data);
        setUserData(response);
        setLoggedInUserId(loggedInUser.id);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error...", err);
      });
  }, []);
  return (
    <div>
      <div className="balanceBody">
        <div className="panel-container">
          <div className="left-panel">
            {!isLoading &&
              balanceData.map((item) => {
                return (
                  <>
                    {item.userId == loggedInUserId && (
                      <div className="user-row">
                        <div
                          className={`username-img  ${
                            active == item.agent.name ? "active" : ""
                          }`}
                          onClick={() => {
                            setRightPanelData(item);
                            setActive(item.agent.name);
                          }}
                        >
                          {/* <img
                            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                            alt="profile-img"
                            className="profile-img-card"
                          /> */}
                          <div className="username">{item.agent.name}</div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
          <div className="right-panel">
            {!isLoading && rightPanelData.userId == loggedInUserId && (
              <div className="balanceInfo">
                <div className="Administrator">
                  <div className="userLabel">Administrator</div>
                  <div className="balance-details">
                    <div className="usdCurrency">
                      {parseFloat(
                        rightPanelData.adminUsdBalance
                      ).toLocaleString("en-US")}{" "}
                      USD
                    </div>
                    <div className="localCurrency">
                      {parseFloat(
                        rightPanelData.adminLocalBalance
                      ).toLocaleString("en-US")}{" "}
                      {rightPanelData.agent.localCurrency}
                    </div>
                  </div>
                  <div className="action-buttons">
                    <Button
                      onClick={() =>
                        handleModalOpen(
                          rightPanelData.id,
                          {
                            USD: rightPanelData.adminUsdBalance,
                            local: rightPanelData.adminLocalBalance,
                          },
                          "add",
                          rightPanelData.agent.localCurrency,
                          "Admin",
                          rightPanelData
                        )
                      }
                      className="bg-[#007bff]"
                    >
                      Add
                    </Button>
                    <Button
                      variant="danger"
                      className="bg-[#dc3545]"
                      onClick={() =>
                        handleModalOpen(
                          rightPanelData.id,
                          {
                            USD: rightPanelData.adminUsdBalance,
                            local: rightPanelData.adminLocalBalance,
                          },
                          "subtruct",
                          rightPanelData.agent.localCurrency,
                          "Admin",
                          rightPanelData
                        )
                      }
                    >
                      Deduct
                    </Button>
                  </div>
                </div>
                <div className="User">
                  <div className="userLabel">User</div>
                  <div className="balance-details">
                    <div className="usdCurrency">
                      {parseFloat(rightPanelData.userUsdBalance).toLocaleString(
                        "en-US"
                      )}{" "}
                      USD
                    </div>
                    <div className="localCurrency">
                      {parseFloat(
                        rightPanelData.userLocalBalance
                      ).toLocaleString("en-US")}{" "}
                      {rightPanelData.agent.localCurrency}
                    </div>
                  </div>
                  <div className="action-buttons">
                    <Button
                      className="bg-[#007bff]"
                      onClick={() =>
                        handleModalOpen(
                          rightPanelData.id,
                          {
                            USD: rightPanelData.userUsdBalance,
                            local: rightPanelData.userLocalBalance,
                          },
                          "add",
                          rightPanelData.agent.localCurrency,
                          "User",
                          rightPanelData
                        )
                      }
                    >
                      Add
                    </Button>
                    <Button
                      className="bg-[#dc3545]"
                      variant="danger"
                      onClick={() =>
                        handleModalOpen(
                          rightPanelData.id,
                          {
                            USD: rightPanelData.userUsdBalance,
                            local: rightPanelData.userLocalBalance,
                          },
                          "subtruct",
                          rightPanelData.agent.localCurrency,
                          "User",
                          rightPanelData
                        )
                      }
                    >
                      Deduct
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading && <LoadingSpinner></LoadingSpinner>}
        {!isLoading && _.isEmpty(balanceData) && (
          <>
            <h4 style={{ marginLeft: "350px", color: "darkblue" }}>
              No balances to show...
            </h4>
          </>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{addOrSubtruct} balance</Modal.Title>
          </Modal.Header>
          <Modal.Body className="balance-modal">
            <p style={{ display: "flex", gap: "10px" }}>
              <input
                className="input--style-4"
                value={modalAmt}
                type="text"
                onChange={(e) => setModalAmt(e.target.value)}
              />
              <select
                className="form-control"
                onChange={(e) => setModalCurrency(e.target.value)}
              >
                <option value={localCurrency} selected>
                  {localCurrency}
                </option>
                <option value="USD">USD</option>
              </select>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              className="bg-blue-500"
              onClick={() => handleUpdate()}
            >
              update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UserBalance;
