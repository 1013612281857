//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class TransactionService {
  getTransactions(filterObj) {
    return axios.get(
      "/api/getTransactions",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addTransaction(TransactionData) {
    return axios({
      method: "POST",
      url: "/api/addTransaction",
      data: TransactionData,
      headers: authHeader(),
    });
  }
  editTransaction(TransactionData) {
    return axios({
      method: "POST",
      data: TransactionData,
      url: "/api/editTransaction",
      headers: authHeader(),
    });
  }
  deleteTransaction(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteTransaction",
      headers: authHeader(),
    });
  }

  getAllTransactions() {
    return axios.get("/api/getAllTransactions");
  }
  getTransactionInfo(infoData) {
    return axios({
      method: "POST",
      data: infoData,
      url: "/api/getTransactionInfo/",
      headers: authHeader(),
    });
  }
  filterTransactions(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterTransactions",
      headers: authHeader(),
    });
  }
}

export default new TransactionService();
