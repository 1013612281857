import axios from "axios";
import authHeader from "./auth-header";
import configs from "../configs/local";
class RequestRequestService {
  getRequests(RequestId) {
    return axios.get(
      `/api/getRequests/${RequestId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterRequests(RequestId) {
    return axios.get(
      `/api/filterRequests/${RequestId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  updateRequest(RequestData) {
    return axios({
      method: "POST",
      data: RequestData,
      url: "/api/updateRequest",
      headers: authHeader(),
    });
  }
  addRequest(RequestData) {
    return axios({
      method: "POST",
      data: RequestData,
      url: "/api/addRequest",
      headers: authHeader(),
    });
  }
  deleteRequest(RequestData) {
    return axios({
      method: "POST",
      data: RequestData,
      url: "/api/deleteRequest",
      headers: authHeader(),
    });
  }
  getRequest(RequestId) {
    return axios.get(
      `/api/getRequest/${RequestId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
}

export default new RequestRequestService();
