//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class AgentService {
  getAgents(filterObj) {
    return axios.get(
      "/api/getAgents",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addAgent(AgentData) {
    return axios({
      method: "POST",
      url: "/api/addAgent",
      data: AgentData,
      headers: authHeader(),
    });
  }
  editAgent(AgentData) {
    return axios({
      method: "POST",
      data: AgentData,
      url: "/api/editAgent",
      headers: authHeader(),
    });
  }
  deleteAgent(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteAgent",
      headers: authHeader(),
    });
  }

  getAllAgents() {
    return axios.get("/api/getAllAgents");
  }
  getAgentInfo(customerId) {
    return axios.get(
      "/api/getAgentInfo/" + customerId,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterAgents(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterAgents",
      headers: authHeader(),
    });
  }
  updateAgentDetails(agentData) {
    return axios({
      method: "POST",
      data: agentData,
      url: "/api/updateAgentDetails",
      headers: authHeader(),
    });
  }
}

export default new AgentService();
