import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import UserService from "../../services/user.service";
import { countries, currencies } from "../../configs/local";
import AgentService from "../../services/agent.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import AuthService from "../../services/auth.service";
import InputMask from "react-input-mask";
import LoadingSpinner from "../loadingSpinner";
import BalanceService from "../../services/balance.service";
import Toggle from "react-toggle";
import _ from "lodash";
import "./users.css";
const Add = ({ handlePageSwitch, currentUser }) => {
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [localCurrency, setLocalCurrency] = useState("");
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");

  async function handleAdd() {
    const userObj = {
      name,
      city,
      country,
      phone,
      email,
      role,
      username,
      password,
    };
    let error = "";
    if (
      userObj.name == "" ||
      userObj.username == "" ||
      userObj.password == "" ||
      userObj.phone == "" ||
      userObj.country == ""
    ) {
      error = "One or more information is missing!";
    }
    //check if the current user role
    if (currentUser.role == "User") {
      _.assign(userObj, {
        localCurrency,
        userId: currentUser.id,
        role: "Agent",
      });

      if (localCurrency == "") error = "One or more information is missing!";
    } else {
      _.assign(userObj, { role });
      if (role == "") error = "One or more information is missing!";
    }

    if (error) {
      setMessage(error);
      setSucceded("");
      setErrored("");
    } else {
      if (currentUser.role == "Admin") {
        UserService.addUser(userObj)
          .then((res) => {
            setErrored("");
            setSucceded("A new user is successfully added!");
            setMessage("");
          })
          .catch((err) => {
            setSucceded("");
            setErrored("New user creation failed!");
            setMessage("");
          });
      } else {
        AgentService.addAgent(userObj)
          .then(async (res) => {
            await BalanceService.addBalance({
              userId: currentUser.id,
              agentId: res.data.id,
            });
            setErrored("");
            setSucceded("A new user is successfully added!");
            setMessage("");
          })
          .catch((err) => {
            setSucceded("");
            setErrored("New user creation failed!");
            setMessage("");
          });
      }
    }
  }

  return (
    <div className="addUserBody">
      <div className="wrapper wrapper--w680">
        <div className="card card-4">
          <div className="card-body">
            <h2 className="title">User Registration Form</h2>

            <form method="POST">
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">
                      Name <span className="required"></span>
                    </label>

                    <input
                      className="input--style-4"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">City</label>

                    <input
                      className="input--style-4"
                      type="text"
                      onChange={(e) => setCity(e.target.value)}
                      name="first_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">
                      Country <span className="required"></span>
                    </label>
                    <div className="input-group-icon">
                      <select
                        className="form-control selectpicker"
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option disabled="disabled" selected="selected">
                          Choose Country
                        </option>
                        {countries.map((c) => {
                          return <option value={c}>{c}</option>;
                        })}
                      </select>
                      <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Email</label>
                    <input
                      className="input--style-4"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="birthday"
                    />
                    <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                  </div>
                </div>
              </div>
              <div className="row row-space">
                {!!currentUser && currentUser.role == "User" && (
                  <div className="col-3">
                    <div className="input-group">
                      <label className="label">
                        Local currency <span className="required"></span>
                      </label>
                      <div className="input-group-icon">
                        <select
                          className="form-control selectpicker"
                          onChange={(e) => setLocalCurrency(e.target.value)}
                        >
                          <option disabled="disabled" selected="selected">
                            Choose currency
                          </option>
                          {currencies.map((c) => {
                            return <option value={c}>{c}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">
                      Phone <span className="required"></span>
                    </label>
                    <input
                      className="input--style-4"
                      type="number"
                      onChange={(e) => setPhone(e.target.value)}
                      name="phone"
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "blue", height: "2px" }} />
              <br />
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">
                      Username <span className="required"></span>
                    </label>
                    <input
                      className="input--style-4"
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">
                      Password <span className="required"></span>
                    </label>
                    <input
                      className="input--style-4"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      name="phone"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
              {!!currentUser && currentUser.role == "Admin" && (
                <div className="input-group">
                  <select
                    className="form-control selectpicker"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option disabled="disabled" selected="selected">
                      Choose role
                    </option>
                    <option value="Admin"> Admin</option>
                    <option value="User"> User</option>
                  </select>
                  <div className="select-dropdown"></div>
                </div>
              )}
              {!!message && <Alert variant="danger">{message}</Alert>}
              {!!succeded && <Alert variant="success">{succeded}</Alert>}
              {!!errored && <Alert variant="danger">{errored}</Alert>}
              <div className="row row-space">
                <div className="row ">
                  <div className="col-12">
                    <div className="input-group">
                      <div className="flexButtons">
                        <Button
                          variant="success"
                          className="update-user-button bg-[#28a745]"
                          onClick={() => handleAdd()}
                        >
                          Add
                        </Button>
                        <Button
                          variant="warning"
                          className="bg-[#ffc107]"
                          style={{ marginLeft: "50px", minWidth: "100px" }}
                          onClick={() => handlePageSwitch()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const Edit = ({ handlePageSwitch, selectedUser, currentUser }) => {
  const [name, setName] = useState(selectedUser.name);
  const [city, setCity] = useState(selectedUser.city);
  const [country, setCountry] = useState(selectedUser.country);
  const [phone, setPhone] = useState(selectedUser.phone);
  const [email, setEmail] = useState(selectedUser.email);
  const [balance, setBalance] = useState(selectedUser.balance);
  const [role, setRole] = useState(selectedUser.role);
  const [username, setUsername] = useState(selectedUser.username);
  const [password, setPassword] = useState("");
  const [updateObject, setUpdateObject] = useState([]);
  const [localCurrency, setLocalCurrency] = useState(
    selectedUser.localCurrency
  );
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [id, setUserId] = useState(selectedUser.id);
  function handleEdit() {
    const userObj = {
      name,
      city,
      country,
      phone,
      email,
      username,
      password,
      id,
    };
    let error = "";
    if (
      userObj.name == "" ||
      userObj.username == "" ||
      userObj.phone == "" ||
      userObj.country == ""
    ) {
      error = "One or more information is missing!";
    }
    if (currentUser.role == "User") {
      _.assign(userObj, {
        localCurrency,
        userId: currentUser.id,
        role: "Agent",
      });

      if (localCurrency == "") error = "One or more information is missing!";
    } else {
      _.assign(userObj, { role });
      if (role == "") error = "One or more information is missing!";
    }
    if (error) {
      setMessage(error);
      setSucceded("");
      setErrored("");
    } else {
      if (password == "") delete userObj.password;
      if (currentUser.role == "Admin") {
        UserService.updateUserDetails(userObj)
          .then((res) => {
            setErrored("");
            setSucceded("User has been successfully updated!");
            setMessage("");
          })
          .catch((err) => {
            setSucceded("");
            setErrored("User update failed!");
            setMessage("");
          });
      } else {
        AgentService.updateAgentDetails(userObj)
          .then((res) => {
            setErrored("");
            setSucceded("User has been successfully updated!");
            setMessage("");
          })
          .catch((err) => {
            setSucceded("");
            setErrored("User update failed!");
            setMessage("");
          });
      }
    }
  }

  return (
    <div className="editUserBody ">
      <div className="wrapper wrapper--w680">
        <div className="card card-4">
          <div className="card-body">
            <h2 className="title">Edit User Form</h2>

            <form method="POST">
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Name</label>
                    <input
                      className="input--style-4"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">City</label>
                    <input
                      className="input--style-4"
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      name="first_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Country</label>
                    <div className="input-group-icon">
                      <div className="input-group-icon">
                        <select
                          className="form-control selectpicker"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option disabled="disabled" selected="selected">
                            Choose currency
                          </option>
                          {countries.map((c) => {
                            return <option value={c}>{c}</option>;
                          })}
                        </select>
                      </div>
                      <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Email</label>
                    <div className="input-group-icon">
                      <input
                        className="input--style-4"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        name="birthday"
                      />
                      <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Currency</label>
                    <div className="input-group-icon">
                      <select
                        className="form-control selectpicker"
                        value={localCurrency}
                        onChange={(e) => setLocalCurrency(e.target.value)}
                      >
                        <option disabled="disabled">Choose currency</option>
                        {currencies.map((c) => {
                          return <option value={c}>{c}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Phone</label>
                    <input
                      className="input--style-4"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="number"
                      name="phone"
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "blue", height: "2px" }} />
              <br />
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Username</label>
                    <input
                      className="input--style-4"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                    <label className="label">Password</label>
                    <input
                      className="input--style-4"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
              {!!currentUser && currentUser.role == "Admin" && (
                <div className="input-group">
                  <div className="rs-select2 js-select-simple select--no-search">
                    <select
                      className="form-control selectpicker"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option disabled="disabled" selected="selected">
                        Choose role
                      </option>
                      <option value="Admin"> Admin</option>
                      <option value="User"> User</option>
                    </select>
                    <div className="select-dropdown"></div>
                  </div>
                </div>
              )}
              {!!message && <Alert variant="danger">{message}</Alert>}
              {!!succeded && <Alert variant="success">{succeded}</Alert>}
              {!!errored && <Alert variant="danger">{errored}</Alert>}
              <div className="row row-space">
                <div className="row ">
                  <div className="col-12">
                    <div className="input-group">
                      <div className="flexButtons">
                        <Button
                          variant="success"
                          className="update-user-button bg-[#28a745]"
                          onClick={() => handleEdit()}
                        >
                          Update
                        </Button>
                        <Button
                          variant="warning"
                          className="update-user-back-button bg-[#ffc107]"
                          onClick={() => handlePageSwitch()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [{ isEdit, isAdd }, setPageState] = useState({ isEdit: 0, isAdd: 0 });
  const [currentUser, setCurrentUser] = useState([]);
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    AuthService.getCurrentUser()
      .then((res) => {
        setCurrentUser(res);
        //Get all users
        UserService.getUsers()
          .then((res) => {
            const response = res.data;
            //Get all agents
            AgentService.getAllAgents().then((res) => {
              setAgents(res.data);
              setUserData(response);
              setIsLoading(false);
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, [isEdit, isAdd]);
  function handleSelection(item) {
    setSelectedUser(item);
    setPageState({ isEdit: 1, isAdd: 0 });
  }
  function handlePageSwitch() {
    setPageState({ isEdit: 0, isAdd: 0 });
    //window.location.reload(false);
  }
  function handleToggle(toggleStatus, user) {
    //check the role
    if (user.role == "Agent")
      AgentService.updateAgentDetails({ status: toggleStatus, id: user.id });
    else UserService.updateUserDetails({ status: toggleStatus, id: user.id });
  }
  return (
    <div>
      {!isEdit ? (
        <div className="userBody">
          {!isLoading &&
            !!currentUser &&
            currentUser.role == "Admin" &&
            userData.map((item, index) => {
              return (
                <div className="users-list">
                  <div className="agents-list">
                    <Table className="usersTable">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Username</Th>
                          <Th>Role</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>
                            <button
                              style={{ color: "blue", fontWeight: "400" }}
                              onClick={() => handleSelection(item)}
                            >
                              {item.name}
                            </button>
                          </Td>
                          <Td>{item.username}</Td>
                          <Td>{item.role}</Td>
                          <Th>
                            <Toggle
                              id="cheese-status"
                              defaultChecked={!!item.status}
                              onChange={(e) =>
                                handleToggle(e.target.checked, item)
                              }
                            />
                          </Th>
                        </Tr>
                        {agents.map((agent, index) => {
                          return (
                            <>
                              {agent.userId == item.id && (
                                <Tr>
                                  <Td>
                                    <button
                                      style={{
                                        color: "blue",
                                        fontWeight: "400",
                                      }}
                                      onClick={() => handleSelection(agent)}
                                    >
                                      {agent.name}
                                    </button>
                                  </Td>
                                  <Td>{agent.username}</Td>
                                  <Td>{agent.role}</Td>
                                  <Th>
                                    <Toggle
                                      id="cheese-status"
                                      defaultChecked={!!agent.status}
                                      onChange={(e) =>
                                        handleToggle(e.target.checked, agent)
                                      }
                                    />
                                  </Th>
                                </Tr>
                              )}
                            </>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>

                  <div className="userAddButton">
                    <Button
                      className="bg-[#007bff]"
                      onClick={() => setPageState({ isEdit: 1, isAdd: 1 })}
                    >
                      Add New
                    </Button>
                  </div>
                </div>
              );
            })}

          {!isLoading && !!currentUser && currentUser.role == "User" && (
            <div className="agents-list">
              <Table className="usersTable">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Username</Th>
                    <Th>Role</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr style={{ backgroundColor: "lightblue" }}>
                    <Td>
                      <button
                        style={{ color: "black", fontWeight: "400" }}
                        onClick={() => handleSelection(currentUser)}
                        disabled={true}
                      >
                        {currentUser.name}
                      </button>
                    </Td>
                    <Td>{currentUser.username}</Td>
                    <Td>{currentUser.role}</Td>
                    <Th>
                      <Toggle
                        id="cheese-status"
                        defaultChecked={!!currentUser.status}
                        onChange={(e) =>
                          handleToggle(e.target.checked, currentUser)
                        }
                        disabled={true}
                      />
                    </Th>
                  </Tr>
                  {agents.map((agent, index) => {
                    return (
                      <>
                        {agent.userId == currentUser.id && (
                          <Tr>
                            <Td>
                              <button
                                style={{
                                  color: "blue",
                                  fontWeight: "400",
                                }}
                                onClick={() => handleSelection(agent)}
                              >
                                {agent.name}
                              </button>
                            </Td>
                            <Td>{agent.username}</Td>
                            <Td>{agent.role}</Td>
                            <Th>
                              <Toggle
                                id="cheese-status"
                                defaultChecked={!!agent.status}
                                onChange={(e) =>
                                  handleToggle(e.target.checked, agent)
                                }
                              />
                            </Th>
                          </Tr>
                        )}
                      </>
                    );
                  })}
                </Tbody>
              </Table>
              <div className="userAddButton">
                <Button
                  className="bg-[#007bff]"
                  onClick={() => setPageState({ isEdit: 1, isAdd: 1 })}
                >
                  Add New
                </Button>
              </div>
            </div>
          )}
          {isLoading && <LoadingSpinner></LoadingSpinner>}
        </div>
      ) : !isAdd ? (
        <Edit
          handlePageSwitch={handlePageSwitch}
          selectedUser={selectedUser}
          currentUser={currentUser}
        ></Edit>
      ) : (
        <Add
          handlePageSwitch={handlePageSwitch}
          currentUser={currentUser}
        ></Add>
      )}
    </div>
  );
};

export default Users;
