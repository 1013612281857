import React, { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import CommissionService from "../../services/commision.service";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import AgentService from "../../services/agent.service";
import LoadingSpinner from "../loadingSpinner";
import TransactionService from "../../services/transaction.service";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import "./commision.css";
import BalanceService from "../../services/balance.service";
const timezone = "America/New_York";

const ApproveOrRejectCommissions = () => {
  const [CommissionData, setCommissionData] = useState([]);
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [currency, setCurrency] = useState("");
  const [numberOfTransactions, setNumberOfTransactions] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [result, setResult] = useState(0);
  const [agent, setAgent] = useState("");
  const [rate, setRate] = useState(0);
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [currentUser, setLoggedInUser] = useState({});
  const [agents, setAgents] = useState([]);
  const [showCalculator, setShowCalculator] = useState(false);
  let currencies = ["local", "USD"];
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      let commissionList = await CommissionService.getCommissions();
      let filteredCommission = commissionList.data.filter(
        (c) => c.userId == loggedInUser.id
      );
      setCommissionData(filteredCommission);
      setLoggedInUser(loggedInUser);
      let agents = await AgentService.getAllAgents();
      agents = await agents.data.filter((a) => a.userId == loggedInUser.id);
      setAgents(agents);
      setLoggedInUser(loggedInUser);
      setIsLoading(false);
    })();
  }, []);
  function handleAccept(item, status) {
    //update the Commission balance record
    CommissionService.updateCommission({ status: status, id: item.id })
      .then(async (res) => {
        let agentBalance = await BalanceService.getBalance(item.agent.id);
        let userUsdBalance = agentBalance.data.userUsdBalance;
        let userLocalBalance = agentBalance.data.userLocalBalance;
        let adminUsdBalance = agentBalance.data.adminUsdBalance;
        let adminLocalBalance = agentBalance.data.adminLocalBalance;

        if (status == "Accepted") {
          if (item.currency == "USD") {
            userUsdBalance = parseInt(userUsdBalance) - parseInt(item.Amt);
            adminUsdBalance = parseInt(adminUsdBalance) - parseInt(item.Amt);
          } else {
            userLocalBalance = parseInt(userLocalBalance) - parseInt(item.Amt);
            adminLocalBalance =
              parseInt(adminLocalBalance) - parseInt(item.Amt);
          }
        }
        BalanceService.updateBalance({
          userLocalBalance: userLocalBalance,
          userUsdBalance: userUsdBalance,
          adminLocalBalance: adminLocalBalance,
          adminUsdBalance: adminUsdBalance,
          id: agentBalance.data.id,
        })
          .then((res) => {
            window.location.reload(false);
          })
          .catch((err) => {});
        window.location.reload(false);
      })
      .catch((err) => {});
  }
  function handleSearch(agentId) {
    setAgent(agentId);
    CommissionService.filteredCommission({ agentId: agentId }).then((res) => {
      setCommissionData(res.data);
    });
  }
  function handleSubmit() {
    let filters = [];
    filters.push({ agentId: agent });
    filters.push({ status: "Paid" });
    filters.push({
      date: { startDate: moment(fromDate), endDate: moment(toDate) },
    });

    if (agent == "" || currency == "" || rate == 0) {
      setMessage("One or more information is missing or invalid");
      setSucceded("");
      setErrored("");
    } else {
      TransactionService.filterTransactions(filters).then((txns) => {
        setMessage("");
        // setTransactions(txns.data);
        setNumberOfTransactions(txns.data.length);
        let totalAmountSum = 0;
        txns.data.forEach((tx) => {
          totalAmountSum = totalAmountSum + parseFloat(tx.rAmount);
        });
        setResult((totalAmountSum * rate) / 100);
        setTotalAmount(totalAmountSum);
      });
    }
  }

  return (
    <div>
      <div className="balanceRequestBody">
        <XyzTransition appear duration="auto">
          <div className="search-row" xyz="fade small stagger  duration-6">
            <select
              className="form-control selectpicker"
              onChange={(e) => handleSearch(e.target.value)}
              xyz-nested
            >
              <option disabled="disabled" selected="selected">
                Select agent
              </option>
              {agents.map((item, index) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {showCalculator ? (
              <>
                <div className="commission-calculator">
                  <div className="commision-form">
                    <div className="commission-item">
                      From:
                      <DatePicker
                        className="form-control"
                        selected={fromDate}
                        style={{ marginLeft: "0" }}
                        onChange={(Date) => setFromDate(Date)}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="commission-item">
                      To:
                      <DatePicker
                        className="form-control"
                        selected={toDate}
                        style={{ marginLeft: "0" }}
                        onChange={(Date) => setToDate(Date)}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="commission-item">
                      <select
                        className="form-control"
                        style={{ minHeight: "40px", marginTop: "0px" }}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option disabled="disabled" selected="selected">
                          Currency
                        </option>
                        {currencies.map((c) => {
                          return <option value={c}>{c}</option>;
                        })}
                      </select>
                    </div>
                    <input
                      style={{
                        maxHeight: "35px",
                        fontSize: "16px",
                        maxWidth: "100px",
                      }}
                      placeholder="Rate"
                      onChange={(e) => setRate(e.target.value)}
                    ></input>
                    <div className="commission-item">
                      <Button
                        variant="primary"
                        className="bg-blue-500"
                        onClick={() => handleSubmit()}
                      >
                        Calculate
                      </Button>
                    </div>
                  </div>
                  <div className="commission-result">
                    <div className="result-item">
                      Transactions: {numberOfTransactions}
                    </div>
                    <div className="result-item">Amount: {totalAmount}</div>
                    <div className="result-item final">
                      Commission: {result}
                    </div>
                  </div>
                  {!!message && <Alert variant="danger">{message}</Alert>}
                  {!!succeded && <Alert variant="success">{succeded}</Alert>}
                  {!!errored && <Alert variant="danger">{errored}</Alert>}
                </div>
              </>
            ) : (
              <div style={{ marginTop: "20px", marginLeft: "50px" }} xyz-nested>
                Do you want to calculate commission for a user?{" "}
                <label
                  className="link-commission-calculator"
                  onClick={() => setShowCalculator(true)}
                >
                  Click Here
                </label>
              </div>
            )}
          </div>
        </XyzTransition>
        {isLoading && (
          <div className="commission-content">
            <LoadingSpinner></LoadingSpinner>
          </div>
        )}
        {!isLoading && CommissionData.length != 0 && (
          <div className="commission-content sm:m-auto sm:w-[70%]">
            <Table className="usersTable">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>From Date</Th>
                  <Th>To Date</Th>
                  <Th>Amount</Th>
                  <Th>Currency</Th>
                  <Th>comment</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <tbody>
                {CommissionData.map((item, index) => {
                  return (
                    <Tr>
                      <Td>{item.agent.name}</Td>
                      <Td>
                        {moment(item.fromDate)
                          .tz(timezone)
                          .format("DD-MM-YYYY")}
                      </Td>
                      <Td>
                        {moment(item.toDate).tz(timezone).format("DD-MM-YYYY")}
                      </Td>
                      <Td>{parseFloat(item.Amt).toLocaleString("en-US")}</Td>
                      <Td>{item.currency}</Td>
                      <Td>{item.comment}</Td>
                      {item.status == "Pending" ? (
                        <Td>
                          <Button
                            className="bg-blue-500"
                            variant="primary"
                            onClick={() => handleAccept(item, "Accepted")}
                          >
                            Accept
                          </Button>{" "}
                          <Button
                            variant="danger"
                            className="bg-[#dc3545]"
                            onClick={() => handleAccept(item, "Rejected")}
                          >
                            Reject
                          </Button>
                        </Td>
                      ) : (
                        <Td>
                          <Button
                            variant={
                              item.status == "Accepted" ? "primary" : "danger"
                            }
                            disabled
                          >
                            {item.status}
                          </Button>{" "}
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {!isLoading && CommissionData.length == 0 && (
          <>
            <div className="noTxText">No commission to show...</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApproveOrRejectCommissions;
