import axios from "axios";
import authHeader from "./auth-header";
import configs from "../configs/local";
class UserService {
  getUsers() {
    return axios.get(
      "/api/getUsers",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }

  updateUserDetails(userData) {
    return axios({
      method: "POST",
      data: userData,
      url: "/api/updateUserDetails",
      headers: authHeader(),
    });
  }

  addUser(userData) {
    return axios({
      method: "POST",
      data: userData,
      url: "/api/addUser",
      headers: authHeader(),
    });
  }
  deleteUser(userData) {
    return axios({
      method: "POST",
      data: userData,
      url: "/api/deleteUser",
      headers: authHeader(),
    });
  }
  getUser(userId) {
    return axios.get(
      `/api/getUser/${userId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
}

export default new UserService();
