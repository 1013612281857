import axios from "axios";
import authHeader from "./auth-header";
import configs from "../configs/local";
class NoteService {
  getNotes() {
    return axios.get(
      "/api/getNotes",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  updateNote(NoteData) {
    return axios({
      method: "POST",
      data: NoteData,
      url: "/api/updateNote",
      headers: authHeader(),
    });
  }
  addNote(NoteData) {
    return axios({
      method: "POST",
      data: NoteData,
      url: "/api/addNote",
      headers: authHeader(),
    });
  }
  deleteNote(NoteData) {
    return axios({
      method: "POST",
      data: NoteData,
      url: "/api/deleteNote",
      headers: authHeader(),
    });
  }
  getNote(NoteId) {
    return axios.get(
      `/api/getNote/${NoteId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filteredNote(noteData) {
    return axios({
      method: "POST",
      data: noteData,
      url: "/api/filteredNote",
      headers: authHeader(),
    });
  }
}

export default new NoteService();
