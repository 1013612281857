//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class RateService {
  getRates(filterObj) {
    return axios.get(
      "/api/getRates",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addRate(RateData) {
    return axios({
      method: "POST",
      url: "/api/addRate",
      data: RateData,
      headers: authHeader(),
    });
  }
  editRate(RateData) {
    return axios({
      method: "POST",
      data: RateData,
      url: "/api/editRate",
      headers: authHeader(),
    });
  }
  deleteRate(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteRate",
      headers: authHeader(),
    });
  }

  getAllRates() {
    return axios.get("/api/getAllRates");
  }
  getRateInfo(customerId) {
    return axios.get(
      "/api/getRateInfo/" + customerId,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterRates(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterRates",
      headers: authHeader(),
    });
  }
}

export default new RateService();
