import React, { useEffect, useState } from "react";
import { Button, Alert, Modal, Badge } from "react-bootstrap";
import NoteService from "../../services/note.service";
import AuthService from "../../services/auth.service";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import AgentService from "../../services/agent.service";
import LoadingSpinner from "../loadingSpinner";
import _ from "lodash";
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import "./notes.css";
const timezone = "America/New_York";

const CreateNote = () => {
  const [NoteData, setNotesData] = useState([]);
  const [note, setNote] = useState(false);
  const [userOption, setUserOption] = useState("");
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUser, setLoggedInUser] = useState({});
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      let notesList = await NoteService.getNotes();
      let filteredNotes = notesList.data.filter(
        (c) => c.userId == loggedInUser.id
      );
      let agents = await AgentService.getAllAgents();
      agents = await agents.data.filter((a) => a.userId == loggedInUser.id);
      setNotesData(filteredNotes);
      setLoggedInUser(loggedInUser);
      setAgents(agents);
      setIsLoading(false);
    })();
  }, []);
  function handleSubmit() {
    let noteData = {
      agentId: userOption,
      userId: currentUser.id,
      note,
    };
    if (
      noteData.userId == "" ||
      noteData.note == "" ||
      noteData.agentId == ""
    ) {
      setMessage("Agent and notes are required!");
      setSucceded("");
      setErrored("");
    } else {
      NoteService.addNote(noteData)
        .then((res) => {
          window.location.reload(false);
        })
        .catch((err) => {});
    }
  }
  function handleSearch(agentId) {
    NoteService.filteredNote({ agentId: agentId }).then((res) => {
      setNotesData(res.data);
    });
  }

  return (
    <div>
      <div className="noteBody">
        <XyzTransition appear duration="auto">
          <div className="search-row" xyz="fade small stagger  duration-6">
            <div>
              <select
                className="form-control"
                style={{ minWidth: "200px" }}
                onChange={(e) => handleSearch(e.target.value)}
              >
                <option disabled="disabled" selected="selected">
                  Filter Notes
                </option>
                {agents.map((item, index) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
        </XyzTransition>

        <div className="error-message">
          {!!message && <Alert variant="danger">{message}</Alert>}
          {!!succeded && <Alert variant="success">{succeded}</Alert>}
          {!!errored && <Alert variant="danger">{errored}</Alert>}
        </div>
        <div className="note-content sm:m-auto sm:w-[70%]">
          <div className="add-note-container">
            <select
              className="form-control selectpicker"
              onChange={(e) => setUserOption(e.target.value)}
            >
              <option disabled="disabled" selected="selected">
                Choose Agent
              </option>
              {agents.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            <textarea
              maxLength={140}
              type="text"
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
            <Button
              variant="primary"
              className="bg-blue-500"
              onClick={() => handleSubmit()}
            >
              Add Note
            </Button>
          </div>
          {!isLoading &&
            NoteData.map((item, index) => {
              return (
                <>
                  <Alert variant="info" className="notes">
                    <Badge style={{ maxWidth: "100px" }}>
                      {item.agent.name}
                    </Badge>
                    <div className="note-date">
                      <div className="box">{item.note}</div>
                      <div
                        style={{
                          fontSize: "13px",
                          color: "blue",
                          marginTop: "5px",
                          opacity: "0.6",
                        }}
                      >
                        {item.createdAt}
                      </div>
                    </div>
                  </Alert>
                </>
              );
            })}
          {isLoading && <LoadingSpinner></LoadingSpinner>}
        </div>
      </div>
    </div>
  );
};

export default CreateNote;
