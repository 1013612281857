//import axios from '../utils/axios.utils';

import authHeader from "./auth-header";
import axios from "axios";
class CurrencyService {
  getCurrencys(filterObj) {
    return axios.get(
      "/api/getCurrencys",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addCurrency(CurrencyData) {
    return axios({
      method: "POST",
      url: "/api/addCurrency",
      data: CurrencyData,
      headers: authHeader(),
    });
  }
  editCurrency(CurrencyData) {
    return axios({
      method: "POST",
      data: CurrencyData,
      url: "/api/editCurrency",
      headers: authHeader(),
    });
  }
  deleteCurrency(data) {
    return axios({
      method: "POST",
      data,
      url: "/api/deleteCurrency",
      headers: authHeader(),
    });
  }

  getAllCurrencys() {
    return axios.get("/api/getAllCurrencys");
  }
  getCurrencyInfo(customerId) {
    return axios.get(
      "/api/getCurrencyInfo/" + customerId,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filterCurrencys(filterData) {
    return axios({
      method: "POST",
      data: filterData,
      url: "/api/filterCurrencys",
      headers: authHeader(),
    });
  }
}

export default new CurrencyService();
