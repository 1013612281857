import React, { useEffect, useState } from "react";
import { Button, Alert, Modal } from "react-bootstrap";
import UserTopUpRequestService from "../../services/userTopUpRequest";
import AuthService from "../../services/auth.service";
import { countries, currencies } from "../../configs/local";
import _ from "lodash";
import "./requests.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
const CreateRequest = () => {
  const [requestData, setRequestData] = useState([]);
  const [addRequest, setAddRequest] = useState(false);
  const [Amt, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [currentUser, setLoggedInUser] = useState({});
  useEffect(() => {
    (async () => {
      let loggedInUser = await AuthService.getCurrentUser();
      let requestList = await UserTopUpRequestService.getTopUpRequest(
        loggedInUser.id
      );
      setRequestData(requestList.data);
      setLoggedInUser(loggedInUser);
    })();
  }, []);
  function handleSubmit() {
    let requestObj = {
      userId: currentUser.userId,
      Amt,
      comment,
      status: "Pending",
      currency,
      agentId: currentUser.id,
    };

    if (
      requestObj.userId == "" ||
      requestObj.Amt == "" ||
      requestObj.Amt == "0" ||
      requestObj.currency == "" ||
      requestObj.agentId == ""
    ) {
      setMessage("One or more information is missing or invalid");
      setSucceded("");
      setErrored("");
    } else {
      UserTopUpRequestService.addTopUpRequest(requestObj)
        .then((res) => {
          setMessage("");
          setSucceded("A new request is successfully added!");
          setErrored("");
          setAddRequest(false);
          window.location.reload(false);
        })
        .catch((err) => {});
    }
  }

  return (
    <div>
      <div className="balanceRequestBody">
        <div className="search-row">
          {addRequest && (
            <div className="request-form">
              <input
                className="input--style-4"
                type="text"
                placeholder="Amount"
                onChange={(e) => setAmount(e.target.value)}
              ></input>
              <select
                className="form-control"
                style={{ minHeight: "40px" }}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option disabled="disabled" selected="selected">
                  Currency
                </option>
                {currencies.map((c) => {
                  return <option value={c}>{c}</option>;
                })}
              </select>
              <input
                className="input--style-4"
                type="text"
                placeholder="comment"
                onChange={(e) => setComment(e.target.value)}
              ></input>
              <Button
                variant="primary"
                className="bg-blue-500"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          )}
          {!addRequest && (
            <Button
              variant="primary"
              className="request-add-button bg-blue-500"
              onClick={() => setAddRequest(true)}
            >
              Add Request
            </Button>
          )}
        </div>
        <div className="request-content sm:m-auto sm:w-[70%]">
          {!!message && <Alert variant="danger">{message}</Alert>}
          {!!succeded && <Alert variant="success">{succeded}</Alert>}
          {!!errored && <Alert variant="danger">{errored}</Alert>}

          {requestData.length != 0 && (
            <Table className="usersTable">
              <Thead>
                <Tr>
                  <Th>Amount</Th>
                  <Th>Currency</Th>
                  <Th>comment</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {requestData.map((item, index) => {
                  return (
                    <Tr>
                      <Td>{parseFloat(item.Amt).toLocaleString("en-US")}</Td>
                      <Td>{item.currency}</Td>
                      <Td>{item.comment || "N/A"}</Td>
                      <Td>
                        <Button
                          variant={
                            item.status == "Accepted"
                              ? "primary"
                              : item.status == "Rejected"
                              ? "danger"
                              : "secondary"
                          }
                          disabled
                        >
                          {item.status}
                        </Button>{" "}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {requestData.length == 0 && (
            <>
              <div className="noTxText">No requests to show...</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateRequest;
