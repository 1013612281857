import { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import TransactionService from "../../services/transaction.service";
import UserService from "../../services/user.service";
import InputMask from "react-input-mask";
import AuthService from "../../services/auth.service";
import AgentService from "../../services/agent.service";
import SenderService from "../../services/sender.service";
import ReceiverService from "../../services/receiver.service";
import CurrenyService from "../../services/currency.service";
import CountrydetailService from "../../services/countrydetail.service";
import BalanceService from "../../services/balance.service";
import "./transaction.css";
import _ from "lodash";
const Edit = ({
  handlePageSwitch,
  handlePageSwitchEdit,
  selectedTxn,
  currentUser = { currentUser },
}) => {
  function getCurrency(item) {
    let curr = currencies.filter((c) => c.id == item.rCurrencyId);
    return curr[0].type;
  }
  const [sName, setsName] = useState(selectedTxn.receiver.sender.name);
  const [sCity, setsCity] = useState(selectedTxn.receiver.sender.city);
  const [sAmount, setsAmount] = useState(selectedTxn.sAmount);
  const [sCountry, setsCountry] = useState(
    selectedTxn.receiver.sender.countrydetail.country
  );
  const [sCurrency, setsCurrency] = useState(
    selectedTxn.receiver.sender.countrydetail.currency.type
  );
  const [sPhone, setsPhone] = useState(selectedTxn.receiver.sender.phone);
  const [sEmail, setsEmail] = useState(selectedTxn.receiver.sender.email);
  const [rName, setrName] = useState(selectedTxn.receiver.name);
  const [rCity, setrCity] = useState(selectedTxn.receiver.city);
  const [rAmount, setrAmount] = useState(selectedTxn.rAmount);
  const [rCountry, setrCountry] = useState(
    selectedTxn.receiver.countrydetail.country
  );
  const [rCurrency, setrCurrency] = useState("");
  const [rPhone, setrPhone] = useState(selectedTxn.receiver.phone);
  const [rEmail, setrEmail] = useState(selectedTxn.receiver.email);
  const [userId, setUserId] = useState(selectedTxn.userId);
  const [agentId, setAgentId] = useState(selectedTxn.agentId);
  const [referenceP, setReference] = useState(
    selectedTxn.receiver.sender.referenceP
  );
  const [ticketNo, setTicketNo] = useState(selectedTxn.ticketNo);
  const [users, setUsers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [succeded, setSucceded] = useState("");
  const [errored, setErrored] = useState("");
  const [status, setStatus] = useState(selectedTxn.status);
  const [sPaid, setsPaid] = useState(selectedTxn.sPaid);
  const [comment, setComment] = useState(selectedTxn.comment);
  const [initialStatus, setInitialStatus] = useState(selectedTxn.status);
  const [changedFields, setChangedFields] = useState([]);
  const [senderCountries, setSenderCountries] = useState([]);
  const [receiverCountries, setReceiverCountries] = useState([]);
  const [sCurrencyId, setsCurrencyId] = useState(selectedTxn.sCurrencyId);
  const [rCurrencyId, setrCurrencyId] = useState(selectedTxn.rCurrencyId);
  const [currencies, setCurrencies] = useState([]);

  async function handleEdit() {
    let txObject = {};
    if (
      sName == "" ||
      sAmount == "" ||
      rName == "" ||
      rAmount == "" ||
      rCurrency == ""
    )
      setErrored(
        "One or more information is missing!  Fields with red (*) are required!"
      );
    else {
      if (changedFields.length > 0) {
        for (let c of changedFields) {
          txObject[c.param] = c.value;
        }
        txObject.id = selectedTxn.id;
        await TransactionService.editTransaction(txObject)
          .then(async (tx) => {
            setErrored("");
            setSucceded("Transaction is successfully updated!");

            //update balance if status changed
            if (!!txObject.status) {
              if (txObject.status == "Paid" && selectedTxn.status != "Paid") {
                let agentBalance = await BalanceService.getBalance(
                  selectedTxn.agentId
                );
                let userUsdBalance = agentBalance.data.userUsdBalance;
                let userLocalBalance = agentBalance.data.userLocalBalance;
                let adminUsdBalance = agentBalance.data.adminUsdBalance;
                let adminLocalBalance = agentBalance.data.adminLocalBalance;

                if (rCurrency == "USD") {
                  userUsdBalance = parseInt(userUsdBalance) - parseInt(rAmount);
                  adminUsdBalance =
                    parseInt(adminUsdBalance) - parseInt(rAmount);
                } else {
                  userLocalBalance =
                    parseInt(userLocalBalance) - parseInt(rAmount);
                  adminLocalBalance =
                    parseInt(adminLocalBalance) - parseInt(rAmount);
                }

                BalanceService.updateBalance({
                  userLocalBalance,
                  userUsdBalance,
                  adminUsdBalance,
                  adminLocalBalance,
                  id: agentBalance.data.id,
                })
                  .then((res) => {
                    // window.location.reload(false);
                  })
                  .catch((err) => {});
              }
              if (txObject.status != "Paid" && selectedTxn.status == "Paid") {
                let agentBalance = await BalanceService.getBalance(
                  selectedTxn.agentId
                );
                let userUsdBalance = agentBalance.data.userUsdBalance;
                let userLocalBalance = agentBalance.data.userLocalBalance;
                let adminUsdBalance = agentBalance.data.adminUsdBalance;
                let adminLocalBalance = agentBalance.data.adminLocalBalance;

                if (rCurrency == "USD") {
                  userUsdBalance = parseInt(userUsdBalance) + parseInt(rAmount);
                  adminUsdBalance =
                    parseInt(adminUsdBalance) + parseInt(rAmount);
                } else {
                  userLocalBalance =
                    parseInt(userLocalBalance) + parseInt(rAmount);
                  adminLocalBalance =
                    parseInt(adminLocalBalance) + parseInt(rAmount);
                }
                BalanceService.updateBalance({
                  userLocalBalance,
                  userUsdBalance,
                  adminUsdBalance,
                  adminLocalBalance,
                  id: agentBalance.data.id,
                })
                  .then((res) => {
                    // window.location.reload(false);
                  })
                  .catch((err) => {});
              } //deduct amount
            }
          })
          .catch((err) => {
            setSucceded("");
            setErrored("Transaction update failed!");
          });
      }
    }
  }

  useEffect(() => {
    AgentService.filterAgents({ userId: currentUser.id }).then(async (res) => {
      let _countries = await CountrydetailService.getAllCountrydetails();
      let currencies = await CurrenyService.getAllCurrencys();
      setCurrencies(currencies.data);
      let curr = currencies.data.filter((c) => c.id == selectedTxn.rCurrencyId);
      setrCurrency(curr[0].type);
      setSenderCountries(_countries.data.filter((c) => c.type == "sender"));
      setReceiverCountries(_countries.data.filter((c) => c.type == "receiver"));
      setAgents(res.data);
    });
  }, []);

  return (
    <div className="addTransaction">
      <div className="page-wrapper bg-gra-02 p-t-130 p-b-100 ">
        <div className="wrapper wrapper--w680">
          <div className="card card-4">
            {currentUser.role != "Agent" ? (
              <div className="card-body">
                <div className="edit-top">
                  <h2 className="title">Edit Transaction</h2>
                  <h3>
                    Ticket No:<span className="ticket-number">{ticketNo}</span>
                  </h3>
                </div>
                <form method="POST">
                  <div className="sender-details">
                    <h4 className="sub-title">
                      <u> Sender Details:</u>
                    </h4>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Name <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            type="text"
                            name="first_name"
                            value={sName}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "sName"
                                ),
                                { param: "sName", value: e.target.value },
                              ])
                            }
                            onChange={(e) => setsName(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">City</label>
                          <input
                            className="input--style-4"
                            type="text"
                            name="first_name"
                            value={sCity}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "sCity"
                                ),
                                { param: "sCity", value: e.target.value },
                              ])
                            }
                            onChange={(e) => setsCity(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Phone <span className="required"></span>
                          </label>
                          <InputMask
                            className="input--style-4"
                            mask="(999) 999-9999"
                            value={sPhone}
                            onChange={(e) => setsPhone(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Amount <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            type="number"
                            value={sAmount}
                            onChange={(e) => setsAmount(e.target.value)}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "sAmount"
                                ),
                                { param: "sAmount", value: e.target.value },
                              ])
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Country<span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => {
                                setChangedFields((oldArray) => [
                                  ...oldArray,
                                  { sCountryId: e.target.value },
                                ]);
                                setsCountry(e.target.value);
                              }}
                              disabled
                            >
                              <option value={sCountry}>{sCountry}</option>
                              {senderCountries.map((c) => {
                                return (
                                  <option value={c.id}>{c.country}</option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">Email</label>
                          <div className="input-group-icon">
                            <input
                              className="input--style-4 js-datepicker"
                              type="text"
                              name="birthday"
                              value={sEmail}
                              onChange={(e) => setsEmail(e.target.value)}
                              onBlur={(e) =>
                                setChangedFields((oldArray) => [
                                  ...oldArray.filter(
                                    (obj) => obj.param !== "sEmail"
                                  ),
                                  { param: "sEmail", value: e.target.value },
                                ])
                              }
                              disabled
                            />
                            <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Currency<span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => {
                                setChangedFields((oldArray) => [
                                  ...oldArray,
                                  { sCurrencyId: e.target.value },
                                ]);
                                setsCurrency(e.target.value);
                              }}
                              disabled
                            >
                              <option value={sCurrency}>{sCurrency}</option>
                              {senderCountries.map((c) => {
                                return (
                                  <option value={c.currency.id}>
                                    {c.currency.type}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">S Paid</label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker w-200"
                              value={sPaid}
                              onChange={(e) => {
                                setChangedFields((oldArray) => [
                                  ...oldArray.filter(
                                    (obj) => obj.param !== "sPaid"
                                  ),
                                  { param: "sPaid", value: e.target.value },
                                ]);
                                setsPaid(e.target.value);
                              }}
                            >
                              <option value="Pending">Pending</option>
                              <option value="Paid">Paid</option>
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="receiver-details">
                    <h4 className="sub-title">
                      <u>Receiver Details:</u>
                    </h4>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Name <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            type="email"
                            name="email"
                            value={rName}
                            onChange={(e) => setrName(e.target.value)}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "rName"
                                ),
                                { param: "rName", value: e.target.value },
                              ])
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">City</label>
                          <input
                            className="input--style-4"
                            type="text"
                            value={rCity}
                            onChange={(e) => setrCity(e.target.value)}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "rCity"
                                ),
                                { param: "rCity", value: e.target.value },
                              ])
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Phone/ACCT <span className="required"></span>
                          </label>
                          <InputMask
                            className="input--style-4"
                            mask="(999) 999-9999"
                            value={rPhone}
                            onChange={(e) => setrPhone(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Amount <span className="required"></span>
                          </label>
                          <input
                            className="input--style-4"
                            type="number"
                            value={rAmount}
                            onChange={(e) => setrAmount(e.target.value)}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "rAmount"
                                ),
                                { param: "rAmount", value: e.target.value },
                              ])
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Country<span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => setrCountry(e.target.value)}
                              disabled
                            >
                              <option selected value={rCountry}>
                                {rCountry}
                              </option>
                              {receiverCountries.map((c) => {
                                return (
                                  <option value={c.id}>{c.country}</option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">Text</label>
                          <input
                            className="input--style-4"
                            type="text"
                            value={rEmail}
                            onChange={(e) => setrEmail(e.target.value)}
                            onBlur={(e) =>
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "rEmail"
                                ),
                                { param: "rEmail", value: e.target.value },
                              ])
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="input-group">
                          <label className="label">
                            Currency<span className="required"></span>
                          </label>
                          <div className="rs-select2 js-select-simple select--no-search">
                            <select
                              className="form-control selectpicker"
                              onChange={(e) => {
                                setChangedFields((oldArray) => [
                                  ...oldArray,
                                  { rCurrencyId: e.target.value },
                                ]);
                                setrCurrency(e.target.value);
                              }}
                              disabled
                            >
                              <option selected value={rCurrency}>
                                {rCurrency}
                              </option>
                              {receiverCountries.map((c) => {
                                return (
                                  <option value={c.currency.id}>
                                    {c.currency.type}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="select-dropdown"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-space">
                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">
                          Agent <span className="required"></span>
                        </label>
                        <div className="rs-select2 js-select-simple select--no-search">
                          <select
                            className="form-control selectpicker"
                            value={agentId}
                            onChange={(e) => {
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "agentId"
                                ),
                                { param: "agentId", value: e.target.value },
                              ]);
                              setAgentId(e.target.value);
                            }}
                          >
                            {agents.map((agent, index) => {
                              return (
                                <option value={agent.id}>{agent.name}</option>
                              );
                            })}
                          </select>
                          <div className="select-dropdown"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">Reference</label>
                        <input
                          className="input--style-4"
                          type="text"
                          value={referenceP}
                          onChange={(e) => setReference(e.target.value)}
                          onBlur={(e) =>
                            setChangedFields((oldArray) => [
                              ...oldArray.filter(
                                (obj) => obj.param !== "referenceP"
                              ),
                              { param: "referenceP", value: e.target.value },
                            ])
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">status</label>
                        <div className="rs-select2 js-select-simple select--no-search">
                          <select
                            className="form-control selectpicker w-200"
                            value={status}
                            onChange={(e) => {
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "status"
                                ),
                                { param: "status", value: e.target.value },
                              ]);
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="Pending">Pending</option>
                            <option value="Paid">Paid</option>
                            <option value="UnPaid">UnPaid</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                          <div className="select-dropdown"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">Comment</label>
                        <textarea
                          className="input--style-4 text-area w-290"
                          type="text"
                          name="first_name"
                          value={comment}
                          onChange={(e) => {
                            setChangedFields((oldArray) => [
                              ...oldArray.filter(
                                (obj) => obj.param !== "comment"
                              ),
                              { param: "comment", value: e.target.value },
                            ]);
                            setComment(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {succeded && <Alert variant="success">{succeded}</Alert>}
                  {errored && <Alert variant="danger">{errored}</Alert>}
                  <div className="row ">
                    <div className="col-12">
                      <div className="input-group">
                        <div className="flexButtons">
                          <Button
                            className="edit-update-button bg-[#28a745] hover:opacity-60"
                            onClick={() => handleEdit()}
                          >
                            Update
                          </Button>
                          <Button
                            variant="warning"
                            className="edit-back-button bg-[#ffc107] hover:opacity-60"
                            onClick={() =>
                              handlePageSwitchEdit(
                                selectedTxn.id,
                                status,
                                comment
                              )
                            }
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="card-body">
                <div className="edit-top">
                  <label>
                    <h3>Ticket Number</h3>
                    <h3 className="ticket-number">{ticketNo}</h3>
                  </label>
                </div>

                <form method="POST">
                  <div className="row row-space">
                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">status</label>
                        <div className="rs-select2 js-select-simple select--no-search">
                          <select
                            className="form-control selectpicker w-200"
                            value={status}
                            onChange={(e) => {
                              setChangedFields((oldArray) => [
                                ...oldArray.filter(
                                  (obj) => obj.param !== "status"
                                ),
                                { param: "status", value: e.target.value },
                              ]);
                              setStatus(e.target.value);
                            }}
                            disabled={status != "Pending"}
                          >
                            <option value="Pending">Pending</option>
                            <option value="Paid">Paid</option>
                            <option value="UnPaid">UnPaid</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                          <div className="select-dropdown"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="input-group">
                        <label className="label">Comment</label>
                        <textarea
                          className="input--style-4 text-area w-500"
                          type="text"
                          name="first_name"
                          value={comment}
                          onChange={(e) => {
                            setChangedFields((oldArray) => [
                              ...oldArray.filter(
                                (obj) => obj.param !== "comment"
                              ),
                              { param: "comment", value: e.target.value },
                            ]);
                            setComment(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {succeded && <Alert variant="success">{succeded}</Alert>}
                  {errored && <Alert variant="danger">{errored}</Alert>}
                  <div className="row ">
                    <div className="col-12">
                      <div className="input-group">
                        <div className="flexButtons">
                          <Button
                            variant="success"
                            className="edit-update-button bg-[#28a745]"
                            onClick={() => handleEdit()}
                          >
                            Update
                          </Button>
                          <Button
                            variant="warning"
                            className="edit-back-button bg-[#ffc107]"
                            onClick={() =>
                              handlePageSwitchEdit(
                                selectedTxn.id,
                                status,
                                comment
                              )
                            }
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
