import axios from "axios";
import authHeader from "./auth-header";
import configs from "../configs/local";
class UserTopUpRequestService {
  getTopUpRequests() {
    return axios.get(
      "/api/getTopUpRequests",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  addTopUpRequest(RequestObj) {
    return axios({
      method: "POST",
      url: "/api/addTopUpRequest",
      data: RequestObj,
      headers: authHeader(),
    });
  }
  getTopUpRequest(RequestId) {
    return axios.get(
      `/api/getTopUpRequest/${RequestId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
}

export default new UserTopUpRequestService();
