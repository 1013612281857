import axios from "axios";
import authHeader from "./auth-header";
import configs from "../configs/local";
class CommissionService {
  getCommissions() {
    return axios.get(
      "/api/getCommissions",
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  updateCommission(CommissionData) {
    return axios({
      method: "POST",
      data: CommissionData,
      url: "/api/updateCommission",
      headers: authHeader(),
    });
  }
  addCommission(CommissionData) {
    return axios({
      method: "POST",
      data: CommissionData,
      url: "/api/addCommission",
      headers: authHeader(),
    });
  }
  deleteCommission(CommissionData) {
    return axios({
      method: "POST",
      data: CommissionData,
      url: "/api/deleteCommission",
      headers: authHeader(),
    });
  }
  getCommission(CommissionId) {
    return axios.get(
      `/api/getCommission/${CommissionId}`,
      { headers: authHeader() },
      { timeout: 5000 }
    );
  }
  filteredCommission(commissionData) {
    return axios({
      method: "POST",
      data: commissionData,
      url: "/api/filteredCommission",
      headers: authHeader(),
    });
  }
}

export default new CommissionService();
