import React, { useEffect, useState } from "react";
import { Route, Link, Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import AgentService from "../services/agent.service";
import RateService from "../services/rate.service";
const useAuth = () => {
  const user = localStorage.getItem("identifier");
  if (user) {
    return true;
  } else {
    return false;
  }
};
let links = {
  Transactions: true,
  Users: false,
  Balance: false,
  Commission: false,
  Requests: false,
  Note: false,
  Rate: false,
};

export default function PrivateRoute({ component: Component, ...rest }) {
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState([]);
  const [activeLink, setActiveLink] = useState("");
  const [agentExist, setAgentExist] = useState(false);

  useEffect(() => {
    AuthService.getCurrentUser()
      .then(async (res) => {
        let agentsList = await AgentService.getAllAgents();
        let _agents = agentsList.data.filter((a) => a.userId == res.id);
        let rateList = await RateService.getAllRates();
        let filteredRates = rateList.data.filter((c) => c.userId == res.id);
        if (
          (_agents.length == 0 || filteredRates.length == 0) &&
          res.role != "Agent"
        )
          setAgentExist(false);
        else setAgentExist(true);
        setCurrentUser(res);
      })
      .catch((err) => {});
  }, []);
  function changeActive(active) {
    //find the key

    setActiveLink(active);
    for (const [key, value] of Object.entries(links)) {
      if (key == active) {
        links[key] = true;
      } else links[key] = false;
    }
  }
  return (
    <Route
      render={() =>
        auth ? (
          <div>
            <div className="navigation">
              <header>
                <label className="active-nav-item">{activeLink}</label>
                <nav>
                  <section className="top-nav">
                    <div className="welcome-text-mobile">
                      Welcome, {currentUser.name}
                    </div>
                    <input id="menu-toggle" type="checkbox" />
                    <label className="menu-button-container" for="menu-toggle">
                      <div className="menu-button"></div>
                    </label>
                    <ul className="menu">
                      {agentExist && (
                        <li key="txns" className="nav-item">
                          <div
                            className={`nav-item-custom ${
                              !!links["Transactions"] ? "active" : ""
                            }`}
                          >
                            <Link
                              to={"/Transactions"}
                              onClick={() => changeActive("Transactions")}
                            >
                              Transactions
                            </Link>
                          </div>
                        </li>
                      )}
                      {!!currentUser && currentUser.role != "Agent" && (
                        <>
                          <li key="users" className="nav-item ">
                            <div
                              className={`nav-item-custom ${
                                !!links["Users"] ? "active" : ""
                              }`}
                            >
                              <Link
                                to={"/Users"}
                                onClick={() => changeActive("Users")}
                              >
                                Users
                              </Link>
                            </div>
                          </li>
                          {agentExist && (
                            <>
                              <li key="balance" className="nav-item ">
                                <div
                                  className={`nav-item-custom ${
                                    !!links["Balance"] ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    to={"/UserBalance"}
                                    onClick={() => changeActive("Balance")}
                                  >
                                    Balance
                                  </Link>
                                </div>
                              </li>
                              <li key="request" className="nav-item ">
                                <div
                                  className={`nav-item-custom ${
                                    !!links["Requests"] ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    to={"/approveOrRejectRequests"}
                                    onClick={() => changeActive("Requests")}
                                  >
                                    Requests
                                  </Link>
                                </div>
                              </li>
                              <li key="appOrRj" className="nav-item ">
                                <div
                                  className={`nav-item-custom ${
                                    !!links["Commission"] ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    to={"/approveOrRejectCommissions"}
                                    onClick={() => changeActive("Commission")}
                                  >
                                    Commission
                                  </Link>
                                </div>
                              </li>
                              <li key="notes" className="nav-item ">
                                <div
                                  className={`nav-item-custom ${
                                    !!links["Note"] ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    to={"/createNote"}
                                    onClick={() => changeActive("Note")}
                                  >
                                    Notes
                                  </Link>
                                </div>
                              </li>
                            </>
                          )}
                          {/* <li key="rate" className="nav-item ">
                            <div
                              className={`nav-item-custom ${
                                !!links["Rate"] ? "active" : ""
                              }`}
                            >
                              <Link
                                to={"/rate"}
                                onClick={() => changeActive("Rate")}
                              >
                                Rate
                              </Link>
                            </div>
                          </li> */}
                        </>
                      )}
                      {!!currentUser && currentUser.role == "Agent" && (
                        <>
                          <li key="crequest" className="nav-item ">
                            <div
                              className={`nav-item-custom ${
                                !!links["Requests"] ? "active" : ""
                              }`}
                            >
                              <Link
                                to={"/createRequest"}
                                onClick={() => changeActive("Requests")}
                              >
                                Requests
                              </Link>
                            </div>
                          </li>
                          <li key="commision" className="nav-item ">
                            <div
                              className={`nav-item-custom ${
                                !!links["Commission"] ? "active" : ""
                              }`}
                            >
                              <Link
                                to={"/createCommission"}
                                onClick={() => changeActive("Commission")}
                              >
                                Commission
                              </Link>
                            </div>
                          </li>
                        </>
                      )}
                      <div className="welcome-text-desktop">
                        Welcome, {currentUser.name}
                      </div>
                      <li key="logout" className="nav-item">
                        <div className="nav-item-button ">
                          <Link to={"/Logout"} className="">
                            <button>LogOut</button>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </section>
                </nav>
              </header>
            </div>
            <Component {...rest} />
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

//export default PrivateRoute;
