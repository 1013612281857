import axios from "axios";
import configs from "../configs/local";
class AuthService {
  login(username, password) {
    return axios
      .post("/api/auth/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.identifier) {
          localStorage.setItem("identifier", JSON.stringify(response.data));
          return response.data;
        } else {
          return response.data;
        }
      });
  }

  getCurrentUser() {
    return axios
      .post(
        "/api/auth/getCurrentUser",
        JSON.parse(localStorage.getItem("identifier"))
      )
      .then((response) => {
        if (response.data.role) {
          return response.data;
        } else {
          //return response.data;
        }
      });
  }
}

export default new AuthService();
